<div *ngIf="transferredEvent" class="transfer-confirmation-main">
  <div class="transfer-confirmation-details">
    <div class="header">
      <span>Event Transferred</span>
    </div>
    <div class="transferred-banner">
      <h1>Thank You!</h1>
      <p>The event organizer will receive an email confirmation shortly.</p>
    </div>
    <div class="event-timeline-container">
      <h3>Event Timeline</h3>
      <app-event-timeline [event]="event"></app-event-timeline>
    </div>

    <div class="reminders">
      <h3>IMPORTANT NEXT STEPS</h3>
      <ol>
        <li>
          Make sure the organizer has <b>received the invite email.</b> If not, find the event in your
          <a (click)="searchFutureEvents()">future events</a>
           section, click the event name, and hit the Resend Invite Button.
        </li>
        <li>
          The <b>organizer must pay for one member</b> before the invite email is sent to the members.
        </li>
        <li>
          If the organizer has any questions regarding their event, they can <b>call JFW at 866-783-5767.</b>
        </li>
      </ol>
    </div>
  </div>

</div>
<div class="transfer-confirmation-buttons">
  <button
    mat-flat-button
    color="primary"
    accent="primary"
    routerLink="/dashboard"
  >
    Back to Dashboard
  </button>
</div>
<div *ngIf="!transferredEvent">
  <p>Placeholder</p>
</div>
