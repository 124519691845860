import { Component, Inject, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventService } from '@jfw-library/ecommerce/core';
import { StoreService } from '@jfw-library/ecommerce/shared';
import { getSelectedStoreFromLocalStorage } from 'business-logic';
import { Event, InStoreEventConfirmChangeData } from 'common-types';
import { delay, firstValueFrom, of } from 'rxjs';
import { EventManagerService } from '../../../services/event-manager-service';

@Component({
  selector: 'app-in-store-confirm-change-modal',
  templateUrl: './in-store-confirm-change.component.html',
  styleUrls: ['./in-store-confirm-change.component.scss'],
})
export class InStoreConfirmChangeModalComponent {
  eventChangeData: InStoreEventConfirmChangeData;
  action: 'switch-retailer' | 'order-online' | 'order-inStore' | undefined;
  event: Event | undefined;
  updatingEvent = signal(false);

  private eventManagerService = inject(EventManagerService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InStoreEventConfirmChangeData,
    private dialogRef: MatDialogRef<InStoreConfirmChangeModalComponent>,
    private eventService: EventService,
    private router: Router,
    private storeService: StoreService
  ) {
    this.eventChangeData = data;
    this.action = data.action;
    this.event = data.event;
  }

  async confirmChange() {
    if (this.event === undefined) {
      throw new Error('Event is undefined');
    }

    console.log(this.eventChangeData);
    if (this.action === 'switch-retailer') {
      return this.switchRetailer(this.event);
    }

    if (this.action === 'order-online') {
      return this.switchToOrderOnline(this.event);
    }

    if (this.action === 'order-inStore') {
      return this.switchToOrderInStore(this.event);
    }
  }

  // async switchRetailer(event: Event) {
  //   if (event.inStoreInfo !== undefined) {
  //     event.inStoreInfo.store = undefined;
  //     event.inStoreInfo.dateSharedWithStore = undefined;
  //     this.eventService.updateEvent(event).then(() => {
  //       this.router.navigate(['event', event.id, 'choose-store']);
  //       this.dialogRef.close();
  //     });

  //     //return;
  //   }
  // }

  async switchRetailer(event: Event) {
    this.updatingEvent.set(true);
    try {
      if (event.inStoreInfo == undefined) {
        throw new Error('Event does not have inStoreInfo');
      }

      const result = await this.storeService.changeRetailerForEvent(event);
      if (result === undefined) {
        throw new Error(
          'Something went wrong trying to swap retailers. Please try again or contact Customer Service.'
        );
      }

      await this.router.navigate(['event', event.id, 'choose-store']);
      return this.closeDialog(true);
    } catch (error) {
      console.error('Error in switchRetailer', error);
      alert(
        'Something went wrong trying to swap retailers. Please try again or contact Customer Service.'
      );
      return this.closeDialog(false);
    }
  }

  /** Switch an order from InStore to Online */
  async switchToOrderOnline(event: Event): Promise<void> {
    this.updatingEvent.set(true);
    await firstValueFrom(of(true).pipe(delay(3000))); // simulate delay;

    try {
      if (event === undefined) {
        throw new Error('Event is undefined');
      }

      //   //this.cartService.clearCartCache(); // don't need this anymore
      let refreshAfterNavigate = false;

      const { updatedEvent } =
        await this.eventManagerService.switchToOrderOnline(event.id);
      try {
        this.eventService.setSelectedEventWithEvent(updatedEvent, 'InStoreConfirmChangeModalComponent - switchToOrderOnline');
      } catch (error) {
        console.error(
          'switchToOrderOnline was successful, but there was an error setting selected event with event',
          error
        );
        refreshAfterNavigate = true;
      }

      const componentRoute = updatedEvent.isSingleUser ? 'select-look' : 'add-looks';
      await this.router.navigate(['event', updatedEvent.id, componentRoute]);
      if (refreshAfterNavigate) {
        // if there was an error setting the selected event with event, reload the page
        return window.location.reload();
      }

      return this.closeDialog(true);
    } catch (error) {
      console.error('Error in switchToOrderOnline', error);
      alert(
        'There was an error switching your event to order online.  Please try again or contact Customer Service.'
      );
      return this.closeDialog(false);
    }
  }

  async switchToOrderInStore(event: Event): Promise<void> {
    this.updatingEvent.set(true);
    // await firstValueFrom(of(true).pipe(delay(3000), map(() => { })));
    // const updatedEvent = event;

    try {
      if (event === undefined) {
        throw new Error('Event is undefined');
      }

      //   //this.cartService.clearCartCache(); // don't need this anymore
      let refreshAfterNavigate = false;

      const lsStore = getSelectedStoreFromLocalStorage();
      const data = {
        ...(lsStore ? { store: lsStore } : undefined)
      };

      const { updatedEvent } =
        await this.eventManagerService.switchToOrderInStore(event.id, data);
      try {
        await this.eventService.setSelectedEventWithEvent(updatedEvent);
      } catch (error) {
        console.error(
          'switchToOrderInStore was successful, but there was an error setting selected event with event',
          error
        );
        refreshAfterNavigate = true;
      }

      const componentRoute = updatedEvent.isSingleUser ? 'select-look' : 'add-looks';
      await this.router.navigate(['event', updatedEvent.id, componentRoute]);
      if (refreshAfterNavigate) {
        // if there was an error setting the selected event with event, reload the page
        return window.location.reload();
      }

      return this.closeDialog(true);
    } catch (error) {
      console.error('Error in switchToOrderOnline', error);
      alert(
        'There was an error switching your event to order online.  Please try again or contact Customer Service.'
      );
      return this.closeDialog(false);
    }


  }

  async closeDialog(success: boolean) {
    this.dialogRef.close({ success });
    await firstValueFrom(this.dialogRef.afterClosed());
    console.log('dialog closed');
    this.updatingEvent.set(false);
  }
}
