<div class="modal-main">
  <div class="close-modal" style="display: flex; height: 40px">
    <div style="flex: 0 0 50%; display: flex; align-items: center"></div>
    <div
      style="
        display: flex;
        flex: 0 0 50%;
        align-items: center;
        justify-content: right;
      "
    >
      <button
        mat-icon-button
        mat-dialog-close
        class="round-icon-button"
        (click)="sendMemberInvites()"
      >
        <mat-icon class="round-icon-center">close</mat-icon>
      </button>
    </div>
  </div>

  <div class="modal-header-container">
    <h3>Add/Edit Members</h3>
  </div>

  <div style="min-height: 100%">
    <mat-dialog-content style="padding-top: 0px; padding-bottom: 5px">
      <div style="color: black; text-align: center">
        <h3>SUCCESS!</h3>
        <p>
          <strong
            >Your member has been added to the event. Do you wish to pay for the
            new member?</strong
          >
        </p>
        <p>
          <button
            mat-flat-button
            color="primary"
            style="min-width: 200px"
            [disabled]="isEventDateLocked(event)"
            (click)="this.prepareCheckout()"
          >
            Yes
          </button>
        </p>
        <p>- or -</p>
        <p>
          <button
            mat-flat-button
            color="primary"
            style="min-width: 200px"
            (click)="this.sendMemberInvites()"
          >
            No
          </button>
        </p>
      </div>
    </mat-dialog-content>
  </div>
</div>
