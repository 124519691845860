import { Component, computed, effect, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthV2Service, EventService } from '@jfw-library/ecommerce/core';
import { StoreInfoModalComponent } from '@jfw-library/ecommerce/shared';
import {
  JfwEmailValidator,
  PhoneFormComponent,
} from '@jfw-library/shared/common-components/form-fields';
import { firebaseUserToShareEventDetails, isEventSharedWithRetailer, isPaidForMember } from 'business-logic';
import {
  Event,
  EventMember,
  Look,
  ShareEventDetails
} from 'common-types';
import { debounceTime, map, ObservedValueOf, tap } from 'rxjs';
import { EventManagerService } from '../../services/event-manager-service';

interface ShareEventDetailsForm {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
  phone?: ObservedValueOf<PhoneFormComponent['formReady']>;
  // eventSize: FormControl<number | null>;
}

@Component({
  selector: 'app-share-event-details',
  templateUrl: './share-event-details.component.html',
  styleUrls: [
    './share-event-details.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class ShareEventDetailsComponent {
  form = new FormGroup<ShareEventDetailsForm>({
    firstName: new FormControl('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    lastName: new FormControl('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    email: new FormControl('', {
      validators: [Validators.required, JfwEmailValidator.isValid()],
      nonNullable: true,
    }),
    // eventSize: new FormControl(0, [
    //   Validators.required,
    //   this.excludeZeroValidator(/^0/),
    // ]),
  });

  event = toSignal<Event>(this.eventService.selectedEvent$);
  inStoreInfo = computed(() => this.event()?.inStoreInfo);
  store = computed(() => this.inStoreInfo()?.store);
  members = computed(() => this.event()?.members ?? []);
  looks = computed(() => this.event()?.looks ?? []);
  looksMap = computed<Map<Look, EventMember[]>>(() => {
    const map = new Map<Look, EventMember[]>();
    for (let look of this.looks()) {
      let arr: EventMember[] = [];
      for (let member of this.members()) {
        if (member.memberLook?.id === look.id) {
          arr.push(member);
        }
      }
      map.set(look, arr);
    }
    return map;
  });

  currentShareEventDetails = computed(() => {
    const inStoreInfo = this.inStoreInfo();
    const authUser = this.authService.currentUser;
    const existingShareEventDetails = inStoreInfo?.shareEventDetails;
    const isSingleUser = this.event()?.isSingleUser;
    const eventSize = existingShareEventDetails?.eventSize ?? isSingleUser ? 1 : this.event()?.members?.length

    const shareEventDetails: ShareEventDetails = {
      ...(existingShareEventDetails ? existingShareEventDetails
        : authUser ? firebaseUserToShareEventDetails(authUser)
          : {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
          }),
      eventSize,
    };

    return shareEventDetails;
  });
  hasPaidMembers = computed(() => this.event()?.members.some(member => isPaidForMember(member)));
  isEventSharedWithRetailer = computed(() => isEventSharedWithRetailer(this.event()));
  useCarousel = computed(() => this.looks().length > 3);
  routeToLastStep = effect(() => {
    if (this.store() === undefined) {
      this.eventService.routeToFirstStep();
    }
  });
  initFormOnNewEvent = effect(() => {
    this.initializeForm(this.currentShareEventDetails());
  });
  disableForm = effect(() => {
    if (this.hasPaidMembers() || this.isEventSharedWithRetailer()) {
      this.form.disable({ emitEvent: false });
    }
  });

  isFormInvalid = signal(false);


  phone = '';
  validatorMsg = toSignal(this.eventManagerService.results$.pipe(map(validator => validator?.msg ?? '')));
  markTouched = effect(() => {
    if (this.isFormInvalid() || this.validatorMsg()) {
      this.form.markAllAsTouched();
    }
  });

  private readonly updateEventOnChanges = toSignal(this.form.valueChanges.pipe(
    debounceTime(1000),
    tap(value => this.updateEvent(value))
  ));

  isSaving = signal(false);
  saved = signal(false);
  saveError = signal(false);


  constructor(
    private eventService: EventService,
    private dialog: MatDialog,
    private authService: AuthV2Service,
    private eventManagerService: EventManagerService
  ) {}


  excludeZeroValidator(input: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const noZeroCheck = input.test(control.value);
      return noZeroCheck ? { notAllowed: { value: control.value } } : null;
    };
  }

  setPhone(phone: string | undefined) {
    if (phone !== undefined) {
      this.phone = phone;
    }
  }

  addChildForm<key extends keyof ShareEventDetailsForm>(
    name: key,
    group: Exclude<ShareEventDetailsForm[key], undefined>
  ) {
    this.form.setControl(name, group, { emitEvent: false });
  }

  initializeForm(shareEventDetails: ShareEventDetails): void {
    this.form.setValue({
      firstName: shareEventDetails.firstName ?? '',
      lastName: shareEventDetails.lastName ?? '',
      email: shareEventDetails.email ?? '',
      phone: { phone: shareEventDetails.phone ?? '' },
      // eventSize: this.eventDetails.eventSize ?? 0,
    }, { emitEvent: false });
  }

  async updateEvent(
    valueChanges: ObservedValueOf<typeof this.form.valueChanges>
  ): Promise<void> {
    console.log('updateEvent called');
    const eventId = this.event()?.id;
    if (!eventId) {
      console.error('No eventId found');
      return;
    }
    const currentDetails = this.currentShareEventDetails();

  // if (this.preventSave) {
  //   console.log('PreventSave is true.  Not saving.');
  //   return;
  // }

    if (this.hasPaidMembers() || this.isEventSharedWithRetailer()) {
      return;
    }

    if (this.form.valid) {
      this.isFormInvalid.set(false);
      const {
        firstName,
        lastName,
        email,
        phone: phoneForm,
      } = valueChanges;

      // build the update object
      const updateShareEventDetails = this.eventManagerService.updateShareEventDetails;
      const shareEventDetailsUpdates: Parameters<typeof updateShareEventDetails>[1] = {};

      const firstNameChanged = firstName !== undefined && firstName.trim() !== currentDetails?.firstName?.trim();
      if (firstNameChanged) {
        console.log('firstName changed');
        shareEventDetailsUpdates.firstName = firstName.trim();
      }

      const lastNameChanged = lastName !== undefined && lastName.trim() !== currentDetails?.lastName?.trim();
      if (lastNameChanged) {
        console.log('lastName changed');
        shareEventDetailsUpdates.lastName = lastName.trim();
      }

      const emailChanged = email !== undefined && email.trim() !== currentDetails?.email?.trim();
      if (emailChanged) {
        console.log('email changed');
        shareEventDetailsUpdates.email = email.trim();
      }

      const phone = phoneForm?.phone;
      const phoneChanged = phone !== undefined && phone.trim() !== currentDetails?.phone?.trim();
      if (phoneChanged) {
        console.log('phone changed');
        shareEventDetailsUpdates.phone = phone.trim();
      }

      if (Object.keys(shareEventDetailsUpdates).length === 0) {
        console.log('No changes to save');
        if (this.saveError() === true) {
          // only remove "not saved" message if it was previously shown, otherwise leave "saved" message
          this.saved.set(false);
          this.saveError.set(false);
        }
        return;
      }

      this.isSaving.set(true);
      this.saved.set(false);
      this.saveError.set(false);
      this.form.disable({ emitEvent: false });

      console.log('Saving with shareEventDetailsUpdates:', shareEventDetailsUpdates);
      try {

        const newShareEventDetails = {
          ...currentDetails,
          ...shareEventDetailsUpdates,
        }
        const { updatedEvent } =
          await this.eventManagerService.updateShareEventDetails(
            eventId,
            newShareEventDetails
          );
        console.log('details successfully updated');
        this.saved.set(true)
        this.saveError.set(false);
        console.log('Setting selected event with save result');
        this.eventService.setSelectedEventWithEvent(updatedEvent, 'ShareEventDetailsComponent -- updateEvent()');
        console.log('After setting selected event.');
        this.isSaving.set(false);
        this.form.enable({ emitEvent: false });
      } catch (error) {
        console.error('Error updating event details', error);
        alert(
          'Sorry, there was an error saving your event details.  Please try again or contact Customer Service.'
        );
        this.initializeForm(currentDetails); // reset the form to the previous values
        try {
          await this.eventService.getSelectedEvent(true, 'EcomEventDetailsComponent -- updateEvent()');
          this.isSaving.set(false);
          this.saved.set(false);
          this.saveError.set(true);
          this.form.enable({ emitEvent: false });
          return;
        } catch (error) {
          console.error('Error reloading event', error);
          this.isSaving.set(false);
          this.saved.set(false);
          this.saveError.set(true);
          alert('The page will reload now.');
          window.location.reload();
        }
      }
    } else {
      console.log('Details Form is not valid.');
      console.log('Form value:', valueChanges);
      this.saved.set(false);
      this.isFormInvalid.set(true);
      this.form.markAllAsTouched();
    }



  }

  // async onUpdate(): Promise<void> {
  //   const eventId = this.event()?.id;
  //   const inStoreInfo = this.inStoreInfo();
  //   if (eventId === undefined || inStoreInfo === undefined) {
  //     console.log("Missing eventId or inStoreInfo.");
  //     return;
  //   }
  //   const eventDetails: ShareEventDetails = this.shareEventDetails();

  //   if (this.form.controls.firstName.valid) {
  //     eventDetails.firstName = this.form.controls.firstName.value;
  //   }
  //   if (this.form.controls.lastName.valid) {
  //     eventDetails.lastName = this.form.controls.lastName.value;
  //   }
  //   if (this.form.controls.email.valid) {
  //     eventDetails.email = this.form.controls.email.value;
  //   }
  //   if (this.form.controls.phone?.valid) {
  //     eventDetails.phone = this.phone;
  //   }

  //   // if (this.form.controls.eventSize.valid) {
  //   //   if (this.form.controls.eventSize.value === null) {
  //   //     this.eventDetails.eventSize = undefined;
  //   //   } else {
  //   //     this.eventDetails.eventSize = this.form.controls.eventSize.value;
  //   //   }
  //   // }
  //   const numMembers = this.event()?.members.length;
  //   if (numMembers !== undefined) {
  //     eventDetails.eventSize = numMembers;
  //   }

  //   // this.event.inStoreInfo!.shareEventDetails = eventDetails;
  //   const needSingleUserUpdate = this.event()?.isSingleUser && eventDetails.eventSize !== 1;
  //   if (eventDetails.eventSize === 1) {
  //     this.event.isSingleUser = true;
  //   } else {
  //     this.event.isSingleUser = false;
  //   }

  //   try {
  //     const { updatedEvent } = await this.eventManagerService.updateShareEventDetails(
  //       eventId,
  //       eventDetails
  //     );

  //     this.eventService.setSelectedEventWithEvent(updatedEvent, 'ShareEventDetailsComponent - onUpdate after shareEventDetailsToStore');

  //   } catch (error) {
  //     alert('Something went wrong sharing event details.');
  //   }
  // }

  onStoreInfo(): void {
    this.dialog.open(StoreInfoModalComponent, {
      panelClass: 'app-full-bleed-dialog',
      minWidth: '240px',
      maxWidth: '90%',
      maxHeight: '90%',
      data: this.store,
      autoFocus: false,
    });
  }
}
