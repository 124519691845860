import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartService } from '@jfw-library/ecommerce/core';
import { isEventDateLocked } from 'business-logic';
import { Event, EventMember } from 'common-types';
import { Subscription } from 'rxjs';
import { EventManagerService } from '../../../services/event-manager-service';

@Component({
  selector: 'app-review-event-new-members-modal',
  templateUrl: './review-event-new-members.component.html',
  styleUrls: ['./review-event-new-members.component.scss'],
})
export class ReviewEventNewMembers implements OnInit, OnDestroy {
  event!: Event;
  private subscription = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Event,
    private dialogRef: MatDialogRef<ReviewEventNewMembers>,
    private cartService: CartService,
    private eventManagerService: EventManagerService,
    private router: Router
  ) {
    this.event = data;
  }

  ngOnInit(): void {
    console.log('Event');
    console.log(this.event);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    console.log('executed destroy');
  }

  sendMemberInvites() {
    this.event.members.forEach((member) => {
      if (
        member.memberProgress === undefined ||
        (member.memberProgress.inviteSent === undefined &&
          member.memberProgress.paid === undefined)
      ) {
        this.sendInvite(this.event, member);
      }
    });

    this.dialogRef.close();
  }

  isEventDateLocked(event: Event): boolean {
    return isEventDateLocked(event);
  }

  prepareCheckout() {
    console.log('hello?');
    this.dialogRef.close({});
    this.cartService.resetCheckoutState();
    this.cartService.checkoutCartSubject.next(undefined);
    this.router.navigate(['/checkout']);
  }

  /** queue a member-invite email in ComQ, then mark inviteSent via event api. */
  async sendInvite(event: Event, member: EventMember): Promise<void> {
    await this.eventManagerService
      .sendMemberInvite(event, member)
      .catch((e) => {
        console.error('Failed to send invite email', e);
        alert(
          'Sorry, there was an error sending the invite. Please try again or contact Customer Service.'
        );
      });
  }
}
