<div class="look-card-container">
  <div class="look-card">
    <div class="look-card-name" (click)="onSelect()">
      <strong>{{ look.title }}</strong>
    </div>

    <div class="look-card-image" (click)="onSelect()">
      <app-look-mannequin
        [disableSelect]="paidMember()"
        [styles]="look.styles"
        [required]="cardRequired()"
        [canSelect]="true"
        [selected]="isSelected()"
        [saving]="saving"
      >
      </app-look-mannequin>
    </div>

    <div class="look-card-buttons">
      <button
        [disabled]="disableModify() || saving"
        mat-icon-button
        [routerLink]="['/customize-look', look.id]"
        [queryParams]="{ event: event.id, edit: true }"
      >
        <mat-icon
          matTooltip="{{ disableModify() ? disabledLookText : 'Edit Look' }}"
          >edit</mat-icon
        >
      </button>
      <button mat-icon-button (click)="onCopy()" [disabled]="saving">
        <mat-icon>file_copy</mat-icon>
      </button>
      <button [disabled]="disableModify() || saving" mat-icon-button (click)="onDelete()">
        <mat-icon
          matTooltip="{{ disableModify() ? disabledLookText : 'Delete Look' }}"
          >delete</mat-icon
        >
      </button>
    </div>
  </div>
</div>
