<div class="modal-main">
  <div class="close-modal" style="display: flex; height: 40px">
    <div style="flex: 0 0 50%; display: flex; align-items: center"></div>
    <div
      style="
        display: flex;
        flex: 0 0 50%;
        align-items: center;
        justify-content: right;
      "
    >
      <button mat-icon-button mat-dialog-close class="round-icon-button">
        <mat-icon class="round-icon-center">close</mat-icon>
      </button>
    </div>
  </div>

  <div class="modal-header-container">
    <h3>Delete Looks</h3>
  </div>

  <div style="min-height: 100%">
    <mat-dialog-content style="padding-top: 0px; padding-bottom: 5px">
      <div style="color: black; text-align: center">
        <p>
          <strong
            >There are members who are currently assigned this look. Are you
            sure you want to delete?</strong
          >
        </p>
        <p>
          <button
            mat-flat-button
            color="primary"
            style="min-width: 200px"
            (click)="this.confirm(true)"
          >
            Yes
          </button>
        </p>
        <p>
          <button
            mat-stroked-button
            color="accent"
            style="min-width: 200px"
            (click)="this.confirm(false)"
          >
            No
          </button>
        </p>
      </div>
    </mat-dialog-content>
  </div>
</div>
