<div class="look-card-container">
  <div class="look-card">
    <div class="look-card-name">
      <strong>{{ look.title }}</strong>
    </div>

    <div class="look-card-image">
      <app-look-mannequin [styles]="look.styles" [saving]="saving" ></app-look-mannequin>
    </div>

    <div class="look-card-buttons">
      <button
        mat-icon-button
        [disabled]="canModifyLook(look.id) || saving"
        [routerLink]="['/customize-look', look.id]"
        [queryParams]="{ event: event.id, edit: true }"
        matTooltip="{{
          canModifyLook(look.id) ? disabledLookText : 'Edit Look'
        }}"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button (click)="onCopy(look.id)" matTooltip="Copy Look" [disabled]="saving">
        <mat-icon>file_copy</mat-icon>
      </button>
      <button
        mat-icon-button
        [disabled]="canModifyLook(look.id) || saving"
        (click)="onDelete(look.id)"
        matTooltip="{{
          canModifyLook(look.id) ? disabledLookText : 'Delete Look'
        }}"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>
