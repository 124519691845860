<div class="main-event">
  <div class="grooms-free" *ngIf="event.eventType === 'Wedding'">
    Groom Rents Free! See Details
    <mat-icon
      class="grooms-free-tooltip"
      matTooltip="After 6 orders in the party are paid and shipped, the groom will receive a credit equal to the cost of their rental (up to $250). Fees and shipping not included."
      matTooltipPosition="right"
      >help_outline
    </mat-icon>
  </div>
  <div class="grooms-free" *ngIf="event.eventType === 'Quinceañera'">
    Honor Escort Rents Free! See Details
    <mat-icon
      class="grooms-free-tooltip"
      matTooltip="After 6 orders in the party are paid and shipped, the honor escort will receive a credit equal to the cost of their rental (up to $250). Fees and shipping not included."
      matTooltipPosition="right"
      >help_outline
    </mat-icon>
  </div>
  <ng-container *ngIf="dealerPortal; else ecommerce">
    <p class="event-step-copy">
      Review information before transferring the event.
    </p>
    <div *ngIf="validatorMsg && nextClicked" class="event-step-validator-msg">
      <strong>{{ validatorMsg }}</strong>
    </div>
  </ng-container>
  <ng-template #ecommerce>
    <p class="event-step-copy">Review your event prior to Checkout.</p>
  </ng-template>

  <div class="timeline">
    <strong>EVENT TIMELINE</strong>
    <div>
      <app-event-timeline [event]="event"></app-event-timeline>
    </div>
  </div>

  <div
    *ngIf="unassignedMembers.length > 0 && !memberView && !dealerPortal"
    class="unassigned-look-warning"
  >
    <div>
      <mat-icon>warning</mat-icon>
      <p>
        The following event members do not have a look assigned to them.
        <a [routerLink]="'../assign-looks'">Click here to assign looks.</a>
      </p>
    </div>
    <ul>
      <li *ngFor="let unassigned of unassignedMembers">
        {{ unassigned.firstName }} {{ unassigned.lastName }}
      </li>
    </ul>
  </div>

  <ng-container *ngIf="dealerPortal">
    <div class="dealer-info">
      <div class="dealer-info-header">
        <h2>Organizer Information</h2>
      </div>
      <div class="dealer-info-details">
        <p>
          {{ eventOrganizer.name }}
        </p>
        <p>
          {{ eventOrganizer.email }}
        </p>
        <p>
          {{ eventOrganizer.phone | phone }}
        </p>
      </div>
    </div>

    <div *ngIf="event.looks && event.looks.length > 0">
      <div class="dealer-info">
        <div class="dealer-info-header">
          <h2>Your Looks</h2>
        </div>
        <div class="dealer-info-details">
          <div *ngIf="useCarousel()" class="look-card-carousel">
            <app-carousel
              [ariaLabel]="'Carousel of looks'"
              [mobileDisplayCount]="1"
              [desktopDisplayCount]="3"
            >
              <ng-container
                *ngFor="let entry of dealerLookMemberMap | keyvalue"
              >
                <ng-container *appCarouselItem>
                  <app-review-event-look-card
                    [look]="entry.key"
                    [members]="entry.value"
                  ></app-review-event-look-card>
                </ng-container>
              </ng-container>
            </app-carousel>
          </div>

          <div *ngIf="!useCarousel()" class="look-card-grid">
            <ng-container *ngFor="let entry of dealerLookMemberMap | keyvalue">
              <app-review-event-look-card
                [look]="entry.key"
                [members]="entry.value"
              ></app-review-event-look-card>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="event.members && membersWithoutLooksExist()">
      <div class="dealer-info">
        <div class="dealer-info-header">
          <h2>MEMBERS WITHOUT LOOKS</h2>
        </div>
        <div class="dealer-info-details">
          <ng-container *ngFor="let member of event.members">
            <div
              *ngIf="
                member.memberLook === undefined ||
                member.memberLook.title === undefined
              "
            >
              <p>{{ member.firstName }} {{ member.lastName }}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="bottom-section" *ngIf="!dealerPortal">
    <div *ngIf="useCarousel()" class="look-card-carousel">
      <app-carousel
        [initialIndex]="activeLookIndex"
        [ariaLabel]="'Carousel of looks'"
        [mobileDisplayCount]="1"
        [desktopDisplayCount]="3"
      >
        <ng-container *ngFor="let entry of map | keyvalue">
          <ng-container *appCarouselItem>
            <app-review-event-look-card
              [look]="entry.key"
              [members]="entry.value"
            ></app-review-event-look-card>
          </ng-container>
        </ng-container>
      </app-carousel>
    </div>

    <div *ngIf="!useCarousel()" class="look-card-grid">
      <ng-container *ngFor="let entry of map | keyvalue">
        <app-review-event-look-card
          [look]="entry.key"
          [members]="entry.value"
        ></app-review-event-look-card>
      </ng-container>
    </div>
  </div>
</div>
