<div class="user-info-container">
  <div class="user-info-header-container">
    <h3>YOUR INFORMATION</h3>
    @if(isFormInvalid()) {
      <strong class="warn form-text">
        PLEASE FILL OUT THE REQUIRED FIELDS
      </strong>
    }
  </div>
  <div [ngClass]="isFormInvalid() ? 'warn required-text' : 'required-text'">
    * Required
  </div>
  @if(event()) {

    <form [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" />
        <mat-error>Please enter a valid email address</mat-error>
      </mat-form-field>
      <app-phone-form
        appearance="outline"
        [initialValue]="currentShareEventDetails().phone ?? ''"
        (formReady)="addChildForm('phone', $event)"
        (valueChange)="setPhone($event)"
      >
      </app-phone-form>
      @if(isSaving()) {
        <div class="saving-container">
          <span class="saving">Saving...</span>
          <mat-progress-bar
            color="primary"
            mode="indeterminate"
          ></mat-progress-bar>
        </div>
      }
      @if(saved()) {
        <div class="save-success">
          Saved
        </div>
      }
      @if(saveError()) {
        <div class="save-error">
          Error saving
        </div>
      }
    </form>

  }
</div>

<div class="retailer-info-container">
  <div class="retailer-info-header-container">
    <h3>RETAILER INFORMATION</h3>
  </div>
  <div class="store-details">
    <p>
      <a class="store-name" disableRipple (click)="onStoreInfo()">{{
        store()?.aname
      }}</a>
    </p>
    <p>{{ store()?.address1 }}</p>
    <p>{{ store()?.address2 }}</p>
    <p>
      {{ store()?.city }}, {{ store()?.state | uppercase }} {{ store()?.zipCode }}
    </p>
    <p>{{ store()?.phone }}</p>
    @if(store()?.distance) {
      <p>{{ store()?.distance | number : '1.1-2' }} miles</p>
    }
    <p class="store-info">
      <a (click)="onStoreInfo()">Store Info</a>
    </p>
  </div>
</div>

@if(looksMap().size) {
  <div class="looks-container">
    <div class="looks-header-container">
      <h3>YOUR LOOKS</h3>
    </div>
    <div class="looks-cards-container">
      @if(useCarousel()) {
        <div class="look-card-carousel">
          <app-carousel
            [ariaLabel]="'Carousel of looks'"
            [mobileDisplayCount]="1"
            [desktopDisplayCount]="3"
          >
            <ng-container *ngFor="let entry of looksMap() | keyvalue">
              <ng-container *appCarouselItem>
                <app-share-event-look-card
                  [look]="entry.key"
                  [members]="entry.value"
                ></app-share-event-look-card>
              </ng-container>
            </ng-container>
          </app-carousel>
        </div>

      } @else {
        <div class="look-card-grid">
          <ng-container *ngFor="let entry of looksMap() | keyvalue">
            <app-share-event-look-card
              [look]="entry.key"
              [members]="entry.value"
            ></app-share-event-look-card>
          </ng-container>
        </div>
      }
    </div>
  </div>
}

<p>
  By clicking on the "Share With Store" button, I agree that my name, email,
  phone number and style selections will be shared with my chosen Jim's Formal
  Wear (JFW) Retailer. Once my information is submitted, I must contact my JFW
  retailer to make any changes or additions to my event.
  <br /><br />JFW will not share your information with any other parties.
</p>
