  <p class="event-step-copy">
    Please confirm the information for the person wearing the outfit.
  </p>

  <div class="outer-box">
    <div class="header-box">
      <h2>Wearer's Information</h2>
    </div>
    <form [formGroup]="wearerForm" (ngSubmit)="updateEventMemberDetails()">
      <p class="required">* Required</p>
      <div class="fields">
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input matInput [formControl]="wearerForm.controls.firstName" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input matInput [formControl]="wearerForm.controls.lastName" />
        </mat-form-field>
      </div>

      <div class="footer">
        <span *ngIf="saved()" class="save-success">Saved.</span>
        <mat-error *ngIf="errorSaving()" class="save-error" >Error saving.</mat-error>
        <button mat-flat-button color="primary" [disabled]="disableSave()">
          @if(!isSaving()) {
            save
          } @else {
            <mat-progress-spinner
              color="primary"
              value="100"
              mode="indeterminate"
              style="margin-left: auto; margin-right: auto"
              diameter="20"
              strokeWidth="2"
            >
            </mat-progress-spinner>
          }
        </button>
      </div>
    </form>
  </div>
