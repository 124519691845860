<div>
  <div class="modal-main">
    <div class="close-modal">
      <button mat-icon-button [mat-dialog-close]="true" class="close-button">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-header-container">
      <h3>Confirm Contact Details</h3>
    </div>
    <div>
      <p>
        Please confirm your name and phone number, in case our team needs to
        contact you about your event.
      </p>
      <div class="form-container">
        <div class="required-text">* Required</div>
        <form [formGroup]="form" (ngSubmit)="this.confirm(true)">
          <div style="display: flex; flex-direction: column">
            <mat-form-field appearance="outline" hideRequiredMarker="true">
              <mat-label>First Name *</mat-label>
              <input
                matInput
                type="text"
                formControlName="firstName"
                required
              />
            </mat-form-field>
            <mat-form-field appearance="outline" hideRequiredMarker="true">
              <mat-label>Last Name *</mat-label>
              <input matInput type="text" formControlName="lastName" required />
            </mat-form-field>

            <app-phone-form
              textAlign="center"
              appearance="outline"
              fontWeight="bold"
              [initialValue]="organizer?.phone ?? ''"
              (formReady)="addChildForm('phone', $event)"
              (valueChange)="setPhone($event)"
            ></app-phone-form>
          </div>
        </form>
      </div>
    </div>
    <div class="flex-container">
      <button
        mat-stroked-button
        (click)="this.confirm(true)"
        data-cy="confirm-organizer"
      >
        Confirm
      </button>
      <button mat-flat-button color="primary" (click)="this.confirm(false)">
        Cancel
      </button>
    </div>
  </div>
  <!-- <div mat-dialog-content>
    <p>
      Please confirm your name and phone number, so our customer service team
      can easily contact you about your event.
    </p>
    <form [formGroup]="form" (ngSubmit)="this.confirm(true)">
      <div style="display: flex">
        <mat-form-field
          style="flex: grow 1; width: 100%"
          appearance="outline"
          hideRequiredMarker="true"
        >
          <mat-label>First Name</mat-label>
          <input matInput type="text" formControlName="firstName" required />
        </mat-form-field>
        <mat-form-field
          style="flex: grow 1; width: 100%"
          appearance="outline"
          hideRequiredMarker="true"
        >
          <mat-label>Last Name</mat-label>
          <input matInput type="text" formControlName="lastName" required />
        </mat-form-field>
      </div>
      <div style="display: flex">
        <app-phone-form
          style="flex: grow 1; width: 100%"
          appearance="outline"
          [initialValue]="organizer?.phone ?? ''"
          (formReady)="addChildForm('phone', $event)"
          (valueChange)="setPhone($event)"
        ></app-phone-form>
      </div>
    </form>
  </div>
  <div class="flex-container" mat-dialog-actions>
    <button
      class="flex-item confirm"
      mat-flat-button
      (click)="this.confirm(true)"
      data-cy="confirm-organizer"
    >
      Confirm
    </button>
    <button
      class="flex-item cancel"
      mat-flat-button
      (click)="this.confirm(false)"
    >
      Cancel
    </button>
  </div> -->
</div>
