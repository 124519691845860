import { Component, Input, OnInit } from '@angular/core';
import { User } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { AuthV1Service } from '@jfw-library/ecommerce/core';
import { EventMember, Look } from 'common-types';
import { LookDetailsModalComponent } from '../../modals/look-details/look-details.component';

@Component({
  selector: 'app-share-event-look-card',
  templateUrl: './share-event-look-card.component.html',
  styleUrls: [
    './share-event-look-card.component.scss',
    '../../../event-manager.component.scss',
  ],
})
export class ShareEventLookCardComponent implements OnInit {
  @Input() look!: Look;
  @Input() members: EventMember[] | undefined;

  // user: firebase.User | null = null;
  user: User | null = null;

  constructor(
    private authV1Service: AuthV1Service,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.user = this.authV1Service.getCurrentUser();
  }

  onDetails(): void {
    this.dialog.open(LookDetailsModalComponent, {
      minWidth: '300px',
      data: this.look,
      panelClass: 'dialog-modal',
      autoFocus: false,
    });
  }

  loggedInUserEmail(member: EventMember): boolean {
    return member.email === this.user?.email;
  }
}
