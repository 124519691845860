import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { Auth, User, authState } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartService, EventService } from '@jfw-library/ecommerce/core';
import {
  getIndividualView,
  getMemberView,
  getOrganizerFromEvent,
} from 'business-logic';
import {
  DealerPortalEnvironment,
  DealerUserEvent,
  EcommerceMainEnvironment,
  Event,
  EventAdmin,
  EventMember,
  Look,
} from 'common-types';
import { Subscription, firstValueFrom } from 'rxjs';
import { EventManagerService } from '../../services/event-manager-service';
import { OrganizerInfoModalComponent } from '../modals/organizer-info/organizer-info.component';
import { ReviewEventNewMembers } from '../modals/review-event-new-members/review-event-new-members.component';

@Component({
  selector: 'app-review-event',
  templateUrl: './review-event.component.html',
  styleUrls: [
    './review-event.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class ReviewEventComponent implements OnInit, AfterViewInit, OnDestroy {
  event!: Event;
  dealerUserEvent!: DealerUserEvent;
  subscription = new Subscription();
  dealerPortal = this.environment.dealerPortal;
  individualView = false;
  memberView = false;
  map = new Map<Look, EventMember[]>();
  dealerLookMemberMap = new Map<Look, EventMember[]>();
  unassignedMembers: EventMember[] = [];
  eventOrganizer!: EventAdmin;
  user: User | null = null;
  activeLookIndex: number = 0;
  transferError!: string;
  validatorMsg = '';
  nextClicked = false;
  isMobile = false;

  private auth: Auth = inject(Auth);

  constructor(
    @Inject('environment')
    private environment: EcommerceMainEnvironment | DealerPortalEnvironment,
    private cartService: CartService,
    private cdr: ChangeDetectorRef,
    private eventManagerService: EventManagerService,
    private dialog: MatDialog,
    private eventService: EventService,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.breakpointObserver.observe(Breakpoints.XSmall).subscribe({
        next: (result) => (this.isMobile = result.matches),
      })
    );

    if (!this.dealerPortal) {
      this.cartService.resetCheckoutState();
    }

    if (this.dealerPortal) {
      this.subscription.add(
        authState(this.auth).subscribe((user) => {
          this.user = user;
        })
      );
    }

    this.subscription.add(
      this.eventService.selectedEvent$.subscribe({
        next: (event) => {
          console.log("new event received in ReviewEventComponent.");
          this.event = event;
          if (this.dealerPortal) {
            this.setEventOrganizer();
            this.setMapForDealer();
          }

          this.individualView = getIndividualView(this.event);
          this.memberView = getMemberView(this.event);

          this.setMap();
          this.getUnassignedMembers();

          if (!this.dealerPortal) {
            this.checkForNewMembers();
          }

        },
      })
    );


    // this.subscription.add(
    //   this.eventService.nextClicked$.subscribe({
    //     next: () => this.onNext(),
    //   })
    // );
  }

  ngAfterViewInit(): void {
    if (!this.dealerPortal) {
      localStorage.removeItem('checkout_state');
      // initialize checkout state
      this.cartService.initializeCheckoutState();
      // this.cartService.checkoutStatusSubject.next(
      //   this.cartService.getStartingCheckoutState()
      // );
    }

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // onNext(): void {
  //   this.nextClicked = true;
  //   if (this.dealerPortal) {
  //     this.sendInviteToOrganizer();
  //   } else {
  //     this.prepareCheckout();
  //   }
  // }

  // validate(): void {
  //   const validator = this.validationService.isEventStepValid(
  //     this.event,
  //     'transfer-event'
  //   );
  //   this.validatorMsg = validator.msg;
  //   if (validator.valid) {
  //     this.eventService.canProceed$.next(validator.valid);
  //   }
  // }

  useCarousel(): boolean {
    return this.event.members.length > 3 || this.isMobile;
  }

  hasAnyonePaid() {
    let hasPaid: boolean = false;

    this.event.members.forEach((member) => {
      if (
        member.memberProgress !== undefined &&
        member.memberProgress.paid !== undefined
      ) {
        hasPaid = true;
      }
    });

    return hasPaid;
  }

  checkForNewMembers() {
    let foundNewMember: boolean = false;

    if (!this.hasAnyonePaid()) {
      console.log('no one has paid');
      return;
    }

    this.event.members.forEach((member) => {
      if (
        member.memberProgress === undefined ||
        (member.memberProgress.inviteSent === undefined &&
          member.memberProgress.paid === undefined)
      ) {
        foundNewMember = true;
      }
    });

    if (foundNewMember) {
      let reviewEventNewMemberDialog = this.dialog.open(ReviewEventNewMembers, {
        data: this.event,
        disableClose: true,
        width: '340px',
      });
    }
  }

  // prepareCheckout() {
  //   this.cartService.resetCheckoutState();
  //   this.cartService.checkoutCartSubject.next(undefined);
  //   this.eventService.canProceed$.next(true);
  // }

  setMap(): void {
    this.map.clear();

    let userId: string = '';

    /// Get the Current UserId to Set the default displayed look in mobile view
    if (this.auth.currentUser?.uid) {
      userId = this.auth.currentUser?.uid;
    }

    let displayedLookCounter: number = 0;

    for (let look of this.event.looks) {
      let membersWithThisLook: EventMember[] = [];

      let foundUser: boolean = false;

      for (let member of this.event.members) {
        if (member.memberLook?.id === look.id) {
          membersWithThisLook.push(member);

          if (member.userId === userId) {
            foundUser = true;
          }
        }
      }

      if (membersWithThisLook.length > 0) {
        this.map.set(look, membersWithThisLook);

        if (foundUser) {
          this.activeLookIndex = displayedLookCounter;
        }

        displayedLookCounter = displayedLookCounter + 1;
      }
    }

    console.log('ActiveLookIndex: ' + this.activeLookIndex);
  }

  // getLookActiveIndex(looks: Look[]) {
  //   let index: number = 0;
  //   let lookCounter: number = 0;

  //   console.log('Looking for userId: ' + this.user?.uid);

  //   for (let look of this.event.looks) {
  //     console.log('Look: ' + look.title);
  //     for (let member of this.event.members) {
  //       if (
  //         member.userId === this.user?.uid &&
  //         member.memberLook?.id === look.id
  //       ) {
  //         console.log('Found MATCH: ' + lookCounter);
  //         index = lookCounter;
  //       }
  //     }
  //     lookCounter = lookCounter + 1;
  //   }

  //   console.log('activeLookIndex: ' + index);
  //   this.activeLookIndex = index;
  // }

  setMapForDealer(): void {
    for (let look of this.event.looks) {
      let arr: EventMember[] = [];

      for (let member of this.event.members) {
        if (member.memberLook?.id === look.id) {
          arr.push(member);
        }
      }

      //if (arr.length > 0) {
      this.dealerLookMemberMap.set(look, arr);
      //}
    }
  }

  // needed for organizerInfoGuard
  public needOrganizerInfo(): boolean {
    // Organizer and no admin in array.
    //https://jfw.atlassian.net/browse/EXT-1282
    // return !this.memberView && !hasAdminConfirmedContactInfo(this.event);
    return false;
    // return this.event.admins[0] === undefined;
  }

  // needed for organizerInfoGuard
  public async saveOrganizerInfo(): Promise<boolean> {
    if (this.needOrganizerInfo()) {
      let dialog = this.dialog.open(OrganizerInfoModalComponent, {
        data: getOrganizerFromEvent(this.event),
        maxWidth: '300px',
        minWidth: '300px',
        height: 'auto',
        panelClass: 'app-full-bleed-dialog',
      });
      const saveInfo = await firstValueFrom(dialog.afterClosed());
      if (saveInfo !== undefined) {
        const admin = saveInfo as EventAdmin;
        const index = this.event.admins.findIndex(
          (previousAdmin: EventAdmin) => {
            return previousAdmin.id === admin.id;
          }
        );
        const addAdmin = index === -1;
        const { updatedEvent } = addAdmin
          ? await this.eventManagerService.addAdminToEvent(this.event.id, admin)
          : await this.eventManagerService.updateOrganizer(
              this.event.id,
              admin.id,
              admin
            );

        this.eventService.setSelectedEventWithEvent(updatedEvent, 'ReviewEventComponent - saveOrganizerInfo');
        return this.router.navigate(['/checkout']);

      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getUnassignedMembers(): void {
    this.unassignedMembers = [];
    this.event.members?.forEach((member: EventMember) => {
      if (!member.memberLook) {
        this.unassignedMembers.push(member);
      }
    });
  }

  // async sendInviteToOrganizer(): Promise<void> {
  //   let response;
  //   const organizer = this.event.admins.filter(
  //     (admin) => admin.userId !== this.user?.uid
  //   )[0];
  //   try {
  //     response = await this.communicationQueueService.queueTransferEventEmail(
  //       this.event,
  //       organizer.email,
  //       organizer.name
  //     );
  //   } catch (error) {
  //     this.eventService.transferEventError$.next(true);
  //     this.validate();
  //   }

  //   if (response !== undefined) {
  //     this.dealerEventService.markEventAsTransferred(this.event.id).subscribe();
  //     this.validate();
  //   } else {
  //     console.log('unable to send transfer email');
  //   }
  // }

  setEventOrganizer(): void {
    this.eventOrganizer = this.event.admins.filter((admin) => {
      return admin.id !== this.event.createdByUserId;
    })[0];
  }

  membersWithoutLooksExist(): boolean {
    let membersWithoutLooks: boolean = false;

    this.event.members.forEach((member) => {
      if (
        member.memberLook === undefined ||
        member.memberLook.title === undefined
      ) {
        membersWithoutLooks = true;
      }
    });

    return membersWithoutLooks;
  }
}
