import { Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { EventService } from '@jfw-library/ecommerce/core';
import { map } from 'rxjs';
import { EventManagerService } from '../../services/event-manager-service';

@Component({
  selector: 'app-choose-store',
  templateUrl: './choose-store.component.html',
  styleUrls: [
    './choose-store.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class ChooseStoreComponent {
  event = toSignal(this.eventService.selectedEvent$);
  validatorMsg = toSignal(this.eventManagerService.results$.pipe(map((validator) => validator?.msg ?? '')));

  constructor(
    private eventService: EventService,
    private eventManagerService: EventManagerService
  ) { }

  storeSelected(): void {
    this.eventManagerService.nextClicked();
  }
}
