import { NgModule, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterModule,
  Routes,
} from '@angular/router';
import { EventService } from '@jfw-library/ecommerce/core';
import { AuthRequiredGuard } from '@jfw-library/ecommerce/shared';
import { Event } from 'common-types';
import { AddLooksComponent } from './components/add-looks/add-looks.component';
import { AddMembersComponent } from './components/add-members/add-members.component';
import { AssignLooksComponent } from './components/assign-looks/assign-looks.component';
import { ChooseStoreComponent } from './components/choose-store/choose-store.component';
import { EventDetailsComponent } from './components/event-details/event-details.component';
import { InStoreSummaryComponent } from './components/in-store-summary/in-store-summary.component';
import { ReviewEventComponent } from './components/review-event/review-event.component';
import { ReviewMeasurementsComponent } from './components/review-measurements/review-measurements.component';
import { SelectLookComponent } from './components/select-look/select-look.component';
import { ShareEventDetailsComponent } from './components/share-event-details/share-event-details.component';
import { TrackProgressComponent } from './components/track-progress/track-progress.component';
import { TransferConfirmationComponent } from './components/transfer-confirmation/transfer-confirmation.component';
import { WearersInfoComponent } from './components/wearers-info/wearers-info.component';
import { EventManagerComponent } from './event-manager.component';
import { ecomEventDetailsDeactivateGuard } from './guards/ecom-event-details/ecom-event-details.guard';
import { InStoreSubmittedGuard } from './guards/in-store/in-store-submitted.guard';
import { InStoreGuard } from './guards/in-store/in-store.guard';
import { OnlineGuard } from './guards/online/online.guard';
import { organizerInfoGuard } from './guards/organizer-info/organizer-info.guard';
// import { TestModalsComponent } from './components/modals/test/test.component';

const eventResolver: ResolveFn<Event> = async (
  route: ActivatedRouteSnapshot,
) => {
  const eventId = route.paramMap.get('eventId');
  const eventService = inject(EventService);
  if (!eventId) throw new Error('Event ID is required');
  const selectedEventId = eventService.selectedEvent?.id;
  if (selectedEventId !== eventId) {
    console.log(
      'Selected eventId does not match route param.  Setting/Fetching event with eventId route param....',
    );
    const event = await eventService.setSelectedEvent(eventId, 'eventResolver -- setSelectedEvent !== eventId');
    return event;
  }
  try {
    const event = await eventService.getSelectedEvent(false, 'eventResolver');
    return event;
  } catch (error) {
    console.log(
      'Handling error from getSelectedEvent() in eventResolver: ',
      error,
    );
    console.log('Fetching from api using eventId route param....');
    const event = await eventService.setSelectedEvent(eventId, 'eventResolver -- getSelectedEvent() error');
    return event;
  }
};

const routes: Routes = [
  {
    path: ':eventId',
    component: EventManagerComponent,
    canActivate: [AuthRequiredGuard],
    resolve: { event: eventResolver },
    children: [
      {
        path: 'add-looks',
        component: AddLooksComponent,
        canActivate: [AuthRequiredGuard, InStoreSubmittedGuard],
      },
      {
        path: 'assign-looks',
        component: AssignLooksComponent,
        canActivate: [AuthRequiredGuard, InStoreSubmittedGuard],
      },
      {
        path: 'add-members',
        component: AddMembersComponent,
        canActivate: [AuthRequiredGuard, InStoreSubmittedGuard],
      },
      {
        path: 'choose-store',
        component: ChooseStoreComponent,
        canActivate: [AuthRequiredGuard, InStoreSubmittedGuard, InStoreGuard],
      },
      {
        path: 'confirmation',
        component: InStoreSummaryComponent,
        canActivate: [AuthRequiredGuard, InStoreGuard],
      },
      {
        path: 'details',
        component: EventDetailsComponent,
        canActivate: [AuthRequiredGuard, InStoreSubmittedGuard],
        canDeactivate: [ecomEventDetailsDeactivateGuard],
      },
      {
        path: 'review-event',
        component: ReviewEventComponent,
        canActivate: [AuthRequiredGuard, InStoreSubmittedGuard, OnlineGuard],
        canDeactivate: [organizerInfoGuard],
      },
      {
        path: 'review-measurements',
        component: ReviewMeasurementsComponent,
        canActivate: [AuthRequiredGuard, InStoreSubmittedGuard, OnlineGuard],
      },
      {
        path: 'select-look',
        component: SelectLookComponent,
        canActivate: [AuthRequiredGuard, InStoreSubmittedGuard],
      },
      {
        path: 'share-event',
        component: ShareEventDetailsComponent,
        canActivate: [AuthRequiredGuard, InStoreSubmittedGuard, InStoreGuard],
      },
      // {
      //   path: 'test-modals',
      //   component: TestModalsComponent,
      //   canActivate: [AuthRequiredGuard],
      // },
      {
        path: 'track-progress',
        component: TrackProgressComponent,
        canActivate: [AuthRequiredGuard, InStoreSubmittedGuard, OnlineGuard],
      },
      {
        path: 'transfer-confirmation',
        component: TransferConfirmationComponent,
        canActivate: [AuthRequiredGuard, InStoreSubmittedGuard, OnlineGuard],
      },
      {
        path: 'wearers-info',
        component: WearersInfoComponent,
        canActivate: [AuthRequiredGuard, InStoreSubmittedGuard],
        canDeactivate: [organizerInfoGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventManagerRoutingModule { }
