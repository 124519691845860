<div class="main-event">
  <!-- <h1 class="hide-xs" data-cy="assign-looks">Assign Looks</h1> -->
  <div class="grooms-free" *ngIf="event.eventType === 'Wedding'">
    Groom Rents Free! See Details
    <mat-icon
      class="grooms-free-tooltip"
      matTooltip="After 6 orders in the party are paid and shipped, the groom will receive a credit equal to the cost of their rental (up to $250). Fees and shipping not included."
      matTooltipPosition="right"
      >help_outline
    </mat-icon>
  </div>
  <div class="grooms-free" *ngIf="event.eventType === 'Quinceañera'">
    Honor Escort Rents Free! See Details
    <mat-icon
      class="grooms-free-tooltip"
      matTooltip="After 6 orders in the party are paid and shipped, the honor escort will receive a credit equal to the cost of their rental (up to $250). Fees and shipping not included."
      matTooltipPosition="right"
      >help_outline
    </mat-icon>
  </div>
  <p class="event-step-copy">
    Assign people to the looks they should wear. If you need to edit a look, go
    to <a routerLink="../add-looks">the looks page</a>.
  </p>
  <div *ngIf="showWarning$ | async" class="event-step-validator-msg">
    <strong>{{ validatorMsg }}</strong>
  </div>

  <div
    *ngFor="let member of members; let memberIndex = index"
    class="member-card"
  >
    <div class="member-card-header">
      <div>
        {{ member.firstName }} {{ member.lastName
        }}<span *ngIf="isPaidForMember(member)" class="paid-divider">|</span
        ><small *ngIf="isPaidForMember(member)">
          Paid
          <mat-icon class="paid-icon">monetization_on</mat-icon></small
        >
      </div>
    </div>

    <div *ngIf="useCarousel()" class="look-card-carousel">
      <app-carousel
        [ariaLabel]="
          'Carousel of looks for ' + member.firstName + ' ' + member.lastName
        "
        [mobileDisplayCount]="1"
        [desktopDisplayCount]="3"
        [initialIndex]="memberLookIndexes[memberIndex]"
      >
        <ng-container *ngFor="let look of looks">
          <ng-container *appCarouselItem>
            <app-assign-looks-card
              [saving]="saving"
              [member]="member"
              [look]="look"
              [isMobile]="isMobile"
              [nextClicked]="!!validatorMsg"
              (keydown.enter)="onSelect(member, look)"
              (clickedLook)="onSelect(member, $event)"
            ></app-assign-looks-card>
          </ng-container>
        </ng-container>
      </app-carousel>
    </div>

    <div *ngIf="!useCarousel()" class="look-card-grid">
      <ng-container *ngFor="let look of looks">
        <app-assign-looks-card
          [member]="member"
          [look]="look"
          [saving]="saving"
          [nextClicked]="!!validatorMsg"
          (keydown.enter)="onSelect(member, look)"
          (clickedLook)="onSelect(member, $event)"
        ></app-assign-looks-card>
      </ng-container>
    </div>
  </div>

  <a
    class="skip-main"
    #skipRef
    (click)="tabTopPage($event)"
    (keyup.enter)="skipClose()"
    href="#customize-look-footer"
  >
    Back to Steps
  </a>
</div>
