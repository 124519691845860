import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AuthV1Service, EventService } from '@jfw-library/ecommerce/core';
import {
  MeasurementService,
  OrderService,
} from '@jfw-library/ecommerce/shared';
import { MeasurementsForm } from '@jfw-library/ecommerce/zod-forms';
import {
  ChestRange,
  HeightRange,
  HipRange,
  NeckRange,
  OutseamRange,
  OverarmRange,
  ShoeSizeRange,
  SleeveRange,
  StomachRange,
  WaistRange,
  WeightRange,
  eventMemberMeasurementsComplete,
  isPaidForMember,
} from 'business-logic';
import { Event, EventMember, StyleType } from 'common-types';
import { Subscription } from 'rxjs';

function measurementsValidator(range: any[]): ValidatorFn {
  return (control: AbstractControl<any, any>): ValidationErrors | null => {
    const value = control.value;
    if (value === null || value === undefined) return null;
    const isValid = range.includes(value);
    return isValid ? null : { range: true };
  };
}

@Component({
  selector: 'app-review-measurements',
  templateUrl: './review-measurements.component.html',
  styleUrls: [
    './review-measurements.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class ReviewMeasurementsComponent implements OnInit, OnDestroy {
  event: Event | undefined;
  unmeasuredMembers!: Array<EventMember>;
  measuredMembers!: Array<EventMember>;
  userId: string | undefined;
  subscription = new Subscription();
  measurementForms = new FormArray<FormGroup<MeasurementsForm>>([]);
  viewOnlyForms = new FormArray<FormGroup<MeasurementsForm>>([]);
  // Used to direct spinner for saving measurements.
  updatingMeasurementsMemberIndex!: Array<boolean>;
  constructor(
    private eventService: EventService,
    private authV1Service: AuthV1Service,
    private orderService: OrderService,
    private measurementService: MeasurementService
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.eventService.selectedEvent$.subscribe({
        next: (event) => {
          this.event = event;
          this.unmeasuredMembers = this.getUnmeasuredMembers(this.getPaidMembers(event.members));
          this.measuredMembers = this.getMeasuredMembers(this.getPaidMembers(event.members));
          if (this.unmeasuredMembers) this.createMeasurementFormControls();
          if (this.measuredMembers) this.createViewOnlyFormControls();
        }
      })
    );
    this.userId = this.authV1Service.getCurrentUser()?.uid;
  }

  createMeasurementFormControls(): void {
    console.log("creating measurement form controls for member(s) without measurements.");
    this.updatingMeasurementsMemberIndex = new Array(
      this.unmeasuredMembers.length
    ).fill(false);
    let measurementFormsUpdated = new FormArray<FormGroup<MeasurementsForm>>([]);
    this.unmeasuredMembers.forEach((member) => {
      const memberHasShoes = member.memberLook?.styles.some(
        (style) => style.styleType === StyleType.Shoes
      );
      const shoeFormControl = !memberHasShoes
        ? new FormControl(null)
        : new FormControl<string | null>(null, {
          validators: [
            Validators.required,
            measurementsValidator(ShoeSizeRange),
          ],
        });

      if (member.id !== undefined) {
        measurementFormsUpdated.push(
          new FormGroup<MeasurementsForm>({
            memberId: new FormControl(member.id),
            derivedFrom: new FormControl(''),
            height: new FormControl<string | null>(null, {
              validators: [
                Validators.required,
                measurementsValidator(HeightRange),
              ],
            }),
            weight: new FormControl<number | null>(null, {
              validators: [
                Validators.required,
                measurementsValidator(WeightRange),
              ],
            }),
            //coatSize: new FormControl(null),
            chest: new FormControl<number | null>(null, {
              validators: [
                Validators.required,
                measurementsValidator(ChestRange),
              ],
            }),
            overarm: new FormControl<number | null>(null, {
              validators: [
                Validators.required,
                measurementsValidator(OverarmRange),
              ],
            }),
            stomach: new FormControl<number | null>(null, {
              validators: [measurementsValidator(StomachRange)],
            }),
            waist: new FormControl<number | null>(null, {
              validators: [
                Validators.required,
                measurementsValidator(WaistRange),
              ],
            }),
            hip: new FormControl<number | null>(null, {
              validators: [
                Validators.required,
                measurementsValidator(HipRange),
              ],
            }),
            outseam: new FormControl<number | null>(null, {
              validators: [
                Validators.required,
                measurementsValidator(OutseamRange),
              ],
            }),
            neck: new FormControl<number | null>(null, {
              validators: [
                Validators.required,
                measurementsValidator(NeckRange),
              ],
            }),
            sleeve: new FormControl<number | null>(null, {
              validators: [
                Validators.required,
                measurementsValidator(SleeveRange),
              ],
            }),
            shoeSize: shoeFormControl,
          })
        );
      }
    });
    this.measurementForms = measurementFormsUpdated;
  }

  createViewOnlyFormControls(): void {
    console.log("creating view-only measurement form controls for member(s) with measurements.");
    let viewOnlyFormsUpdated = new FormArray<FormGroup<MeasurementsForm>>([]);
    this.measuredMembers.forEach((member) => {
      // const memberHasShoes = member.memberLook?.styles.some(
      //   (style: EcomStyle) => style.styleType === StyleType.Shoes
      // );
      // const shoeFormControl = !memberHasShoes
      //   ? new FormControl(null)
      //   : new FormControl<string | null>(null, { validators: [Validators.required] });

      if (member.id !== undefined) {
        if (member.measurements) {
          const {
            derivedFrom,
            height,
            weight,
            //coatSize,
            chest,
            overarm,
            stomach,
            waist,
            hip,
            outseam,
            neck,
            sleeve,
            shoeSize,
          } = member.measurements;
          viewOnlyFormsUpdated.push(
            new FormGroup<MeasurementsForm>({
              memberId: new FormControl(member.id),
              derivedFrom: new FormControl(derivedFrom ?? ''),
              height: new FormControl(height ?? '', Validators.required),
              weight: new FormControl(weight ?? null, Validators.required),
              //coatSize: new FormControl(coatSize ?? ''),
              chest: new FormControl(chest ?? null, Validators.required),
              overarm: new FormControl(overarm ?? null, Validators.required),
              stomach: new FormControl(stomach ?? null),
              waist: new FormControl(waist ?? null, Validators.required),
              hip: new FormControl(hip ?? null, Validators.required),
              outseam: new FormControl(outseam ?? null, Validators.required),
              neck: new FormControl(neck ?? null, Validators.required),
              sleeve: new FormControl(sleeve ?? null, Validators.required),
              shoeSize: new FormControl(shoeSize ?? null),
            })
          );
        }
      }
    });
    this.viewOnlyForms = viewOnlyFormsUpdated;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async saveMeasurements(member: EventMember): Promise<void> {
    const memberIndex = this.unmeasuredMembers.findIndex(
      (memberToFind) => member.id === memberToFind.id
    );
    this.updatingMeasurementsMemberIndex[memberIndex] = true;
    if (member.measurements !== undefined) {
      member.measurements.submitted = true;
    }

    if (this.event !== undefined) {
      // await this.eventService.updateEventMember(this.event, member);
      const { updatedEvent } = await this.measurementService.updateMemberMeasurements(this.event.id, [
        member,
      ]);

      this.eventService.setSelectedEventWithEvent(updatedEvent, 'ReviewMeasurementsComponent - saveMeasurements');

      await this.orderService.updateOrderMeasurements(
        this.event.id,
        member.id!,
        member.measurements!
      );
      this.updatingMeasurementsMemberIndex[memberIndex] = false;

      /* now handled in selectedEvent$ subscription
      this.unmeasuredMembers = this.getUnmeasuredMembers(this.event.members);
      this.measuredMembers = this.getMeasuredMembers(this.event.members);
      this.createMeasurementFormControls();
      this.createViewOnlyFormControls();
      */
    }
  }

  /* not used
    hasPaidMembers(members: EventMember[]): boolean {
    return this.getPaidMembers(members).length > 0;
    }
  */

  getUnmeasuredMembers(paidMembers: EventMember[]): Array<EventMember> {
    return paidMembers.filter(
      (member: EventMember) => {
        return this.isNotMeasured(member);
      }
    );
  }

  getMeasuredMembers(paidMembers: EventMember[]): Array<EventMember> {
    return paidMembers.filter(
      (member: EventMember) => {
        return !this.isNotMeasured(member);
      }
    );
  }

  private isNotMeasured(member: EventMember): boolean {
    return !eventMemberMeasurementsComplete(member);
  }

  private getPaidMembers(members: EventMember[]): Array<EventMember> {
    let membersPaid: Array<EventMember> = [];

    // membersPaid = getMembersUserPaidFor(this.event, this.userId!);

    members.forEach((member) => {
      if (isPaidForMember(member)) {
        membersPaid.push(member);
      }
    });
    return membersPaid;
  }
}
