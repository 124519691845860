import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventService } from '@jfw-library/ecommerce/core';
import { ValidationService } from '@jfw-library/ecommerce/shared';
import { getPurchasedLookIds } from 'business-logic';
import { Event, Look } from 'common-types';
import { Subscription } from 'rxjs';
import { EventManagerService } from '../../services/event-manager-service';

@Component({
  selector: 'app-select-look',
  templateUrl: './select-look.component.html',
  styleUrls: [
    './select-look.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class SelectLookComponent implements OnInit, AfterViewInit, OnDestroy {
  event!: Event;
  selectedLookId: string | undefined;
  looks: Look[] = [];
  subscription = new Subscription();
  purchasedLooks: Array<string | undefined> = [];
  validatorMsg = '';
  nextClicked = false;
  memberLookIndex = 0;
  saving = false;
  isMobile = false;

  private eventManagerService = inject(EventManagerService);

  constructor(
    private eventService: EventService,
    private validationService: ValidationService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.eventService.selectedEvent$.subscribe({
        next: (event) => (this.event = event),
      })
    );

    this.subscription.add(
      this.eventService.validateStep$.subscribe({
        next: () => this.validate(),
      })
    );

    this.subscription.add(
      this.breakpointObserver.observe(Breakpoints.XSmall).subscribe({
        next: (result) => (this.isMobile = result.matches),
      })
    );

    // this.subscription.add(
    //   this.eventService.nextClicked$.subscribe((nextClicked) => {
    //     this.nextClicked = nextClicked;
    //   })
    // );

    this.subscription.add(
      this.eventManagerService.results$.subscribe((validator) => {
        if (validator) {
          this.validatorMsg = validator.msg;
          this.nextClicked = true;
          this.cdr.detectChanges();
        }
      })
    );

    this.looks = this.event.looks;
    this.purchasedLooks = getPurchasedLookIds(this.event);
    this.event.members.forEach((member) => {
      this.selectedLookId = member.memberLook?.id;
    });
    this.setMemberLookIndex();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private validate(): void {
    console.log('Validating select-look step.');
    const validator = this.validationService.isEventStepValid(
      this.event,
      'select-look'
    );
    this.validatorMsg = validator.msg;
    this.eventService.canProceed$.next(validator.valid);
  }

  private setMemberLookIndex(): void {
    const member = this.event.members[0];
    const memberLookId = member?.memberLook?.id;

    let lookIndex = this.event.looks.findIndex(
      (look) => look.id === memberLookId
    );

    if (lookIndex === -1) {
      lookIndex = 0;
    }

    this.memberLookIndex = lookIndex;
  }

  public useCarousel(): boolean {
    return this.event.looks.length > 3 || this.isMobile;
  }

  public async selectLook(selectedLook: Look): Promise<void> {
    console.log('Selecting look with id: ', selectedLook.id);
    this.saving = true;
    this.cdr.detectChanges();
    const shouldToggleOff = this.event.members.some(
      (member) => member.memberLook?.id === selectedLook.id
    );
    if (shouldToggleOff) {
      console.log('toggling off');
      this.selectedLookId = undefined;
    } else {
      // toggle on
      console.log('toggling on');
      this.selectedLookId = selectedLook.id;
    }
    // const originalMembers = { ...this.event.members };
    // const newMembers = getNewMembersArrayWithSelectedLook(selectedLook, this.event.members);
    // this.event.members = newMembers; // update local event members array for UI.
    try {
      const selectLookResult = await this.eventManagerService.selectLook(
        this.event.id,
        selectedLook.id
      );
      console.log('Look selected successfully.', selectLookResult);
      const { updatedEvent } = selectLookResult;
      this.eventService.setSelectedEventWithEvent(updatedEvent, 'SelectLookComponent - selectLook');
      this.saving = false;
      this.cdr.detectChanges();
      // update the UI, if necessary, to reflect the actual changes made to the event.
      // const wasToggledOff = updatedEvent.members.every(member => member?.memberLook === undefined);
      // if (wasToggledOff) {
      //   console.log("toggling off after update");
      //   this.selectedLookId = undefined;
      // } else {
      //   console.log("toggling on after update");
      //   this.selectedLookId = selectedLook.id;
      // }
    } catch (error) {
      console.error('Error selecting look: ', error);
      // this.event.members = originalMembers; // revert local event members array.
      alert('Error selecting look. Please try again.');
      // undo the toggle
      if (shouldToggleOff) {
        this.selectedLookId = selectedLook.id;
      } else {
        this.selectedLookId = undefined;
      }
      this.saving = false;
      this.cdr.detectChanges();
    }

    // this.validate();
  }

  public async copyLook(lookId: string): Promise<void> {
    this.saving = true;
    try {
      const result = await this.eventManagerService.copyLook(
        this.event.id,
        lookId
      );
      console.log('copyLook result: ', result);

      const { updatedEvent } = result;

      this.eventService.setSelectedEventWithEvent(updatedEvent, 'SelectLookComponent - copyLook');

      console.log('Look copied successfully');
      this.saving = false;
      return;
    } catch (error) {
      console.error('Error copying look: ', error);
      alert('There was an error copying the look.  Please try again.');
      this.saving = false;
      return;
    }

    // let lookArrayCopy = [...this.event.looks];
    // let copyIndex = lookArrayCopy.findIndex((look) => {
    //   return look.id === lookId;
    // });
    // if (copyIndex !== undefined) {
    //   let copy = JSON.parse(JSON.stringify(lookArrayCopy[copyIndex]));
    //   if (copy !== undefined) {
    //     copy.name = copy?.name + ' Copy';
    //     const newId: string = (
    //       Math.floor(Math.random() * 999998) + 1
    //     ).toString();
    //     copy.id = newId;
    //     this.event!.looks.push(copy);
    //     if (this.event !== undefined) {
    //       this.eventService.updateEvent(this.event);
    //     }
    //   }
    // }
  }

  public async deleteLook(lookId: string | undefined): Promise<void> {
    if (lookId === undefined) {
      return;
    }
    this.saving = true;
    try {
      const result = await this.eventManagerService.deleteLook(
        this.event.id,
        lookId
      );
      console.log('Look deleted successfully');
      const { updatedEvent } = result;
      this.eventService.setSelectedEventWithEvent(updatedEvent, 'SelectLookComponent - deleteLook');
      this.saving = false;
      return;
    } catch (error) {
      console.error('Error deleting look: ', error);
      alert('Error deleting look. Please try again.');
      this.saving = false;
      return;
    }
  }
}
