import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { EventService } from '@jfw-library/ecommerce/core';
import { Event } from 'common-types';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InStoreSubmittedGuard {
  constructor(
    private eventService: EventService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.eventService.selectedEvent$.pipe(
      map((event: Event) => {
        if (
          event.inStoreInfo !== undefined &&
          event.inStoreInfo.dateSharedWithStore !== undefined
        ) {
          this.router.navigate(['/event', event.id, 'confirmation']);
          return false;
        } else {
          return true;
        }
      }),
    );
  }
}
