import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Look } from 'common-types';

@Component({
  selector: 'app-assign-looks-image-modal',
  templateUrl: './assign-looks-image.component.html',
  styleUrls: ['./assign-looks-image.component.scss'],
})
export class AssignLooksImageModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public look: Look) {}
}
