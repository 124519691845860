<div class="look-card-container">
  <div class="look-card">
    <div class="look-card-name">
      <strong>{{ look.title }}</strong>
    </div>

    <div class="look-card-image">
      <button mat-stroked-button color="accent" (click)="onDetails()">
        Details
      </button>
      <app-look-mannequin [styles]="look.styles"></app-look-mannequin>
    </div>

    <div *ngIf="members" class="look-card-members">
      <div class="assigned-to">Look Assigned To:</div>
      <ul *ngIf="members.length > 0">
        <li
          *ngFor="let member of members"
          [ngClass]="loggedInUserEmail(member) ? 'logged-in-member' : ''"
        >
          {{ member.firstName | uppercase }} {{ member.lastName | uppercase }}
        </li>
      </ul>
      <ul *ngIf="members.length < 1">
        <li style="color: #dbdcdd">N/A</li>
      </ul>
    </div>
  </div>
</div>
