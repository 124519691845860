import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PhoneFormComponent } from '@jfw-library/shared/common-components/form-fields';
import { getFirstNameFromName, getLastNameFromName } from 'business-logic';
import { EventAdmin } from 'common-types';
import { ObservedValueOf } from 'rxjs';

interface OrganizerInfoModalForm {
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  phone?: ObservedValueOf<PhoneFormComponent['formReady']>;
}

@Component({
  selector: 'app-organizer-info-modal',
  templateUrl: './organizer-info.component.html',
  styleUrls: ['./organizer-info.component.scss'],
})
export class OrganizerInfoModalComponent {
  form: FormGroup<OrganizerInfoModalForm>;
  phone: string = '';
  organizer!: EventAdmin | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EventAdmin | undefined,
    private dialogRef: MatDialogRef<OrganizerInfoModalComponent>
  ) {
    if (data === undefined) {
      this.organizer = {
        name: '',
        phone: '',
        id: '',
        role: '',
        email: '',
        isOrganizer: false,
      };
      this.form = new FormGroup<OrganizerInfoModalForm>({
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
      });
    } else {
      this.organizer = data;
      var firstName = getFirstNameFromName(this.organizer.name);
      var lastName = getLastNameFromName(this.organizer.name);
      this.form = new FormGroup<OrganizerInfoModalForm>({
        firstName: new FormControl(firstName ?? '', Validators.required),
        lastName: new FormControl(lastName ?? '', Validators.required),
      });
    }
  }

  setPhone(phone: string | undefined) {
    if (phone !== undefined) {
      this.phone = phone;
    }
  }

  addChildForm<key extends keyof OrganizerInfoModalForm>(
    name: key,
    group: Exclude<OrganizerInfoModalForm[key], undefined>
  ) {
    this.form.setControl(name, group);
  }

  confirm(confirm: boolean): boolean {
    let lsUser = JSON.parse(localStorage.getItem('user')!);

    let infoUserId: string = lsUser.uid;

    if (this.organizer?.id !== undefined && this.organizer.id !== '') {
      infoUserId = this.organizer.id;
    }

    console.log('OrgId');
    console.log(infoUserId);

    if (confirm) {
      this.form.markAllAsTouched();
      if (!this.form.valid) {
        return false;
      }
      let info = {
        //id: this.organizer?.id ?? self.crypto.randomUUID(),
        id: infoUserId,
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        name: this.form.value.firstName + ' ' + this.form.value.lastName,
        phone: this.form.value.phone?.phone ?? '',
        email: this.organizer?.email ?? '',
        role: this.organizer?.role ?? 'Organizer',
        confirmedInfo: true,
      };
      this.dialogRef.close(info);
      return true;
    } else {
      this.dialogRef.close();
      return false;
    }
  }
}
