import {
  ActivatedRouteSnapshot,
  CanDeactivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { ReviewEventComponent } from '../../components/review-event/review-event.component';
import { WearersInfoComponent } from '../../components/wearers-info/wearers-info.component';

export const organizerInfoGuard: CanDeactivateFn<
  ReviewEventComponent | WearersInfoComponent
> = (
  component: ReviewEventComponent | WearersInfoComponent,
  currentRoute: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState: RouterStateSnapshot
): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    // only applies when going into checkout
    if (nextState.url !== '/checkout') {
      resolve(true);
    }
    console.log('checkout');
    if (!component.needOrganizerInfo()) {
      resolve(true);
    } else {
      resolve(await component.saveOrganizerInfo());
    }
  });
};
