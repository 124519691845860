import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { isPaidForMember } from 'business-logic';
import { EventMember, Look } from 'common-types';
import { AssignLooksImageModalComponent } from '../../modals/assign-looks-image/assign-looks-image.component';

@Component({
  selector: 'app-assign-looks-card',
  templateUrl: './assign-looks.component.html',
  styleUrls: [
    './assign-looks.component.scss',
    '../../../event-manager.component.scss',
  ],
})
export class AssignLooksCardComponent {
  private _member: EventMember = {} as EventMember;

  @Input()
  set member(member: EventMember) {
    this._member = member;
    // console.log("member received", this.member);
  }

  get member() {
    return this._member;
  }
  // @Input() member!: EventMember;
  @Input() look!: Look;
  @Input() saving = false;
  @Input() nextClicked!: boolean;
  @Input() isMobile!: boolean;
  @Output() clickedLook = new EventEmitter<Look>();

  constructor(private dialog: MatDialog) {}

  isPaidForMember(member: EventMember): boolean {
    return isPaidForMember(member);
  }

  isSelected(look: Look): boolean {
    const isSelected = look.id === this.member?.memberLook?.id;
    // console.log("isSelected", isSelected);
    return isSelected;
  }

  onSelect(look: Look): void {
    if (isPaidForMember(this.member)) {
      return;
    }
    this.clickedLook.emit(look);
  }

  keyDown(look: Look): void {
    if (isPaidForMember(this.member)) {
      return;
    }
    this.clickedLook.emit(look);
  }

  cardRequired(): boolean {
    return this.nextClicked && !this.member.memberLook;
  }

  onEnlarge(look: Look): void {
    this.dialog.open(AssignLooksImageModalComponent, {
      data: look,
      autoFocus: false,
    });
  }
}
