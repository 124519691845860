import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  convertUnixTimestampToPrettyDate,
  getLegacyProductId,
  getRentalShippingDateRange,
  getResaleShippingDateRange,
  getTrackingUrl,
  isResaleWarehouse,
} from 'business-logic';
import { EcomStyle, EventMember, OrderDetail } from 'common-types';

export type ShippingInfo = {
  trackingNumber?: string;
  shippingFrom?: string;
  shippingTo?: string;
  type: string;
  items: Array<EcomStyleExtension>;
};

export type EcomStyleExtension = EcomStyle & {
  trackingNumber?: string;
  orderStatus?: string;
};

@Component({
  selector: 'app-shipping-details-modal',
  templateUrl: './shipping-details.component.html',
  styleUrls: ['./shipping-details.component.scss'],
})
export class ShippingDetailsModalComponent implements OnInit {
  shippingInfo: Array<ShippingInfo> | undefined;

  constructor(
    public dialogRef: MatDialogRef<ShippingDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public member: EventMember & { eventDate: number }
  ) {}

  ngOnInit(): void {
    console.log(this.member);
    this.shippingInfo = [];
    let styles = this.getEcomStyleExtension();
    styles.forEach((style: EcomStyleExtension) => {
      if (style.trackingNumber !== undefined) {
        let i = this.shippingInfo!.findIndex((info: ShippingInfo) => {
          return style.trackingNumber === info.trackingNumber;
        });
        if (i > -1) {
          // existing shipping info.
          this.shippingInfo![i].items.push(style);
        } else {
          // No shipping info yet. create new.
          this.shippingInfo!.push({
            trackingNumber: style.trackingNumber,
            type: 'tracking',
            items: [style],
          });
        }
      } else {
        let type = isResaleWarehouse(style) ? 'resale' : 'rental';
        let i = this.shippingInfo!.findIndex((info: ShippingInfo) => {
          return info.type === type;
        });
        if (i > -1) {
          // in shipping info already
          this.shippingInfo![i].items.push(style);
        } else {
          // not there yet.
          if (type === 'resale') {
            let [fromDate, toDate] = getResaleShippingDateRange(
              Date.now() / 1000
            );
            this.shippingInfo!.push({
              type: type,
              items: [style],
              shippingFrom: fromDate,
              shippingTo: toDate,
            });
          } else {
            // rental
            console.log('rental');
            let [fromDate, toDate] = getRentalShippingDateRange(
              this.member.eventDate
            );
            this.shippingInfo!.push({
              type: type,
              items: [style],
              shippingFrom: convertUnixTimestampToPrettyDate(
                fromDate / 1000,
                true
              ),
              shippingTo: convertUnixTimestampToPrettyDate(toDate / 1000, true),
            });
          }
        }
      }
    });
    console.log(this.shippingInfo);
  }

  getEcomStyleExtension(): Array<EcomStyleExtension> {
    let ecomStyleExtension: Array<EcomStyleExtension> = [];
    if (this.member.memberLook !== undefined) {
      this.member.memberLook.styles.forEach((ecomStyle: EcomStyle) => {
        let i = this.member.orderDetails?.findIndex((detail: OrderDetail) => {
          return (
            getLegacyProductId(ecomStyle.styleType).trim() ===
            detail.productId.trim()
          );
        });
        if (i !== undefined && i > -1) {
          // matching detail
          if (
            this.member.orderDetails![i].trackingNumber !== undefined &&
            this.member.orderDetails![i].trackingNumber!.trim() !== ''
          ) {
            ecomStyleExtension.push({
              ...ecomStyle,
              trackingNumber: this.member.orderDetails![i].trackingNumber,
              orderStatus: this.member.orderDetails![i].lineStatus,
            });
          } else {
            // have details but no tracking
            ecomStyleExtension.push(ecomStyle);
          }
        } else {
          // not found, push it without tracking/status.
          ecomStyleExtension.push(ecomStyle);
        }
      });
    }
    return ecomStyleExtension;
  }

  getTrackingLink(trackingNumber: string) {
    return getTrackingUrl(trackingNumber);
  }
}
