import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type ConfirmSaveEcomEventDetailsModalData = {
  propChanged: 'eventName' | 'eventDate' | undefined;
};
@Component({
  selector: 'app-confirm-save-ecom-event-details',
  templateUrl: './confirm-save-ecom-event-details.html',
  styleUrls: ['./confirm-save-ecom-event-details.scss'],
})
export class ConfirmSaveEcomEventDetailsModalComponent {
  propChanged: 'eventName' | 'eventDate' | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmSaveEcomEventDetailsModalData,
    private dialogRef: MatDialogRef<ConfirmSaveEcomEventDetailsModalComponent>
  ) {
    this.propChanged = data.propChanged;
  }



  confirm(continueNoSave: boolean) {
    this.dialogRef.close({
      continueNoSave,
    });
  }
}
