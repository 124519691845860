<div style="padding: 20px; text-align: center">
  <h2>We're sorry</h2>
  <p style="margin-bottom: 40px">
    It looks like other recent changes were applied to your event. Let's make
    sure you have the most current information.
  </p>
  <button mat-stroked-button color="accent" (click)="close()">
    Please Try Again
  </button>
</div>
