import { Component, computed, effect, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventService } from '@jfw-library/ecommerce/core';
import { getOrganizerFromEvent } from 'business-logic';
import { EventAdmin } from 'common-types';
import {
  firstValueFrom
} from 'rxjs';
import { EventManagerService } from '../../services/event-manager-service';
import { OrganizerInfoModalComponent } from '../modals/organizer-info/organizer-info.component';

interface WearerForm {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
}

@Component({
  selector: 'app-wearers-info',
  templateUrl: './wearers-info.component.html',
  styleUrls: [
    './wearers-info.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class WearersInfoComponent {
  public wearerForm = new FormGroup<WearerForm>({
    firstName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    lastName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  event = toSignal(this.eventService.selectedEvent$);
  eventId = computed(() => this.event()?.id);
  member = computed(() => this.event()?.members[0]);
  memberId = computed(() => this.member()?.id);
  setForm = effect(() => {
    const member = this.member();
    console.log("Setting form values", member);
    this.wearerForm.setValue({
      firstName: member?.firstName ?? '',
      lastName: member?.lastName ?? '',
    });
  });


  valueChanges = toSignal(this.wearerForm.valueChanges.pipe());

  hasNameChanged = computed(() => {
    const member = this.member();
    const formValues = this.valueChanges();
    const newFirstName = formValues?.firstName?.trim();
    const existingFirstName = member?.firstName?.trim();
    const newLastName = formValues?.lastName?.trim();
    const existingLastName = member?.lastName

    const nameChanged = existingFirstName !== newFirstName || existingLastName !== newLastName;

    console.log({ existingFirstName, newFirstName, existingLastName, newLastName, nameChanged });
    return nameChanged;
  });

  disableSave = computed(() => {
    const isFormValid = this.wearerForm.valid;
    const allowSave = this.hasNameChanged() && isFormValid && !this.isSaving();
    const disable = !allowSave;
    // console.log("Disable save", disable);
    return disable;
  });
  isSaving = signal(false);
  saved = signal(false);
  errorSaving = signal(false);

  // private member$ = this.eventService.selectedEvent$.pipe(
  //   map((event) => event.members[0]),
  //   tap((member) => {
  //     this.wearerForm.setValue({
  //       firstName: member.firstName ?? '',
  //       lastName: member.lastName ?? '',
  //     });
  //   })
  // );

  async updateEventMemberDetails() {
    const eventId = this.eventId();
    const memberId = this.memberId();
    if (!eventId || !memberId) {
      console.log("Missing eventId or member id.");
      return;
    }

    if (!this.hasNameChanged()) {
      console.log("No changes to save.");
      return;
    }

    this.isSaving.set(true);
    this.saved.set(false);
    this.errorSaving.set(false);

    const { firstName, lastName } = this.wearerForm.value;

    try {
      const { updatedEvent } = await firstValueFrom(this.eventManagerService.updateMemberDetails(eventId, memberId, {
        firstName,
        lastName,
      }));
      this.eventService.setSelectedEventWithEvent(updatedEvent, 'WearersInfoComponent - updateEventMemberDetails');
      this.isSaving.set(false);
      this.saved.set(true);
    } catch (error) {
      console.error("Error updating member details.", error);
      this.isSaving.set(false);
      this.errorSaving.set(true);
    }
  }

  // private saving$ = new BehaviorSubject<boolean>(false);

  // private saveResult$ = this.saving$.pipe(
  //   filter((saving) => !!saving),
  //   switchMap(() => this.saveMember$)
  // );

  // private saveMember$ = this.eventService.selectedEvent$.pipe(
  //   switchMap((event) => {
  //     const memberId = event.members[0].id;

  //     const firstName = this.wearerForm.controls.firstName.value;
  //     const lastName = this.wearerForm.controls.lastName.value;

  //     if (memberId) {
  //       return this.eventManagerService
  //         .updateMemberDetails(event.id, memberId, {
  //           firstName: firstName,
  //           lastName: lastName,
  //         })
  //         .pipe(
  //           catchError(() => of(false)),
  //           tap(() => this.saving$.next(false))
  //         );
  //     }
  //     return of(null);
  //   })
  // );

  // public readonly vm$ = combineLatest([
  //   this.member$,
  //   this.wearerForm.valueChanges.pipe(startWith(this.wearerForm.value)),
  //   this.saving$,
  //   this.saveResult$.pipe(startWith(null)),
  // ]).pipe(
  //   map(([member, formValue, saving, saveResult]) => {
  //     if (saving) {
  //       saveResult = null;
  //     }

  //     if (saveResult !== true && saveResult != false && saveResult != null) {
  //       member = saveResult.updatedMembersArray[0];
  //     }

  //     const firstName = this.wearerForm.controls.firstName.value.trim();
  //     const lastName = this.wearerForm.controls.lastName.value.trim();

  //     const nameChanged = !(
  //       firstName === member.firstName?.trim() && lastName === member.lastName?.trim()
  //     );

  //     const formValid = this.wearerForm.valid;
  //     const allowSave = nameChanged && formValid && !saving;

  //     return { member, allowSave, saveResult };
  //   })
  // );

  constructor(
    private eventManagerService: EventManagerService,
    private eventService: EventService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  // public onSubmit(): void {
  //   this.saving$.next(true);
  // }

  // needed for organizerInfoGuard
  public needOrganizerInfo(): boolean {
    // Organizer and no admin in array.
    // https://jfw.atlassian.net/browse/EXT-1282
    // return !hasAdminConfirmedContactInfo(event);
    return false;
    // event.admins[0] === undefined;
  }

  // needed for organizerInfoGuard
  public async saveOrganizerInfo(): Promise<boolean> {
    if (this.needOrganizerInfo()) {
      const event = await firstValueFrom(this.eventService.selectedEvent$);
      let dialog = this.dialog.open(OrganizerInfoModalComponent, {
        data: getOrganizerFromEvent(event),
        maxWidth: '300px',
        minWidth: '300px',
        height: 'auto',
        panelClass: 'app-full-bleed-dialog',
      });
      const saveInfo = await firstValueFrom(dialog.afterClosed());
      if (saveInfo !== undefined) {
        const admin = saveInfo as EventAdmin;
        const index = event.admins.findIndex((previousAdmin: EventAdmin) => {
          return previousAdmin.id === admin.id;
        });
        const addAdmin = index === -1;
        const { updatedEvent } = addAdmin
          ? await this.eventManagerService.addAdminToEvent(event.id, admin)
          : await this.eventManagerService.updateOrganizer(
              event.id,
              admin.id,
              admin
            );

        this.eventService.setSelectedEventWithEvent(updatedEvent);
        return this.router.navigate(['/checkout']);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
