// config.service.ts in EventManagerModule
import { Injectable } from '@angular/core';
import { DealerPortalEnvironment, EcommerceMainEnvironment } from 'common-types';

/** Since EventManagerModule is lazy-loaded, the environment variables cannot be passed in directly,
 * for example via a forRoot() function like is done with EcommerceSharedModule.
 * Instead, a service must be used to pass the environment value via a function */
@Injectable({ providedIn: 'root' })
export class EventManagerEnvironmentService {
  private environment: EcommerceMainEnvironment | DealerPortalEnvironment | undefined;

  setEnvironment(environment: EcommerceMainEnvironment | DealerPortalEnvironment) {
    this.environment = environment;
  }

  getEnvironment() {
    return this.environment;
  }
}
