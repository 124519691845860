<div class="modal-main">
  <div class="close-modal">
    <button mat-icon-button [mat-dialog-close]="true" class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="modal-header-container">
    <h3>Shipping Details</h3>
  </div>

  <div class="style-list-container">
    <ng-container *ngFor="let info of shippingInfo; index as i">
      <div class="shipping-banner">
        <div class="shipment-number">SHIPMENT {{ i + 1 }}</div>
        <div class="shipment-info">
          <ng-container *ngIf="info.trackingNumber !== undefined">
            <a [href]="getTrackingLink(info.trackingNumber)" target="_blank"
              >Track Package</a
            >
          </ng-container>
          <ng-container *ngIf="info.trackingNumber === undefined">
            ETA: {{ info.shippingFrom }} to {{ info.shippingTo }}
          </ng-container>
        </div>
      </div>
      <ng-container *ngFor="let item of info.items">
        <div class="style-entry">
          <span class="style-type">
            {{ item.styleGroup === "Rental" ? "RENT" : "BUY" }} -
            {{ item.styleType }}
          </span>
          <span class="style-title">
            {{ item.marketingTitle }}
          </span>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
