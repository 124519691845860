import { AfterViewInit, Component, computed, effect, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService, ScrollService } from '@jfw-library/ecommerce/core';
import { EcomCommunicationQueueService } from '@jfw-library/ecommerce/shared';
import {
  EcomEventDetailsFormControls,
  EcomEventDetailsFormSchema,
  IndividualOrGroup,
  OnlineOrInStore,
  ZodFormUtilities,
} from '@jfw-library/ecommerce/zod-forms';
import {
  JfwEmailValidator,
  PhoneFormComponent,
} from '@jfw-library/shared/common-components/form-fields';
import {
  canEventBeCreatedOnline,
  convertDateToUnixTimestamp,
  convertUnixTimestampToDate,
  generateUuid,
  getCoOwnerLeadByDays,
  getIndividualInStoreView,
  getIndividualView,
  getMaxDateForEvent,
  getOnlineLeadTimeDays,
  getOrganizerFromEvent,
  isEventInStore,
  isEventSharedWithRetailer,
  isMemberAdminForEvent,
} from 'business-logic';
import {
  Event,
  EventAdmin,
  EventMember,
  EventNameSchema,
  EventTypeOptions,
  InStoreEventConfirmChangeData,
  UserEvent
} from 'common-types';
import { firstValueFrom, ObservedValueOf, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { EventManagerService } from '../../services/event-manager-service';
import { EVENT_MANAGER_ENVIRONMENT } from '../../tokens/event-manager-environment.token';
import { GroupIndividualConfirmChangeData, GroupIndividualConfirmChangeModalComponent } from '../modals/group-individual-confirm-change/group-individual-confirm-change.component';
import { InStoreConfirmChangeModalComponent } from '../modals/in-store-confirm-change/in-store-confirm-change.component';
// interface EventDetailsForm {
//   eventName: FormControl<string>;
//   eventType: FormControl<EventType | ''>;
//   //individualOrGroup: FormControl<string>;
//   onlineOrInStore: FormControl<string>;
//   eventDate?: ObservedValueOf<DateFormComponent['formReady']>;
// }

interface PermissionsForm {
  id: FormControl<string>;
  userId: FormControl<string>;
  sendEmailNotification: FormControl<boolean>;
  memberSelected: FormControl<string | null>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  role: FormControl<string>;
  email: FormControl<string>;
  phone?: ObservedValueOf<PhoneFormComponent['formReady']>;
}

const dateIsTooSoonValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const form = control as FormGroup<EcomEventDetailsFormControls>;
  if (!form || !form.controls.eventDate || !form.controls.onlineOrInStore) {
    return null;
  }
  const eventDate = form.controls.eventDate.value;
  const isOnline =
    form.controls.onlineOrInStore.value === OnlineOrInStore.online;
  if (eventDate !== 0 && isOnline) {
    if (!canEventBeCreatedOnline(eventDate)) {
      // console.log("Date is too soon in dateIsTooSoonValidator");
      return { dateIsTooSoon: true };
    }
    // console.log("Date is not too soon in dateIsTooSoonValidator");
    return null;
  }
  // console.log("event is not online in dateIsTooSoonValidator");
  return null;
};

@Component({
  selector: 'app-ecom-event-details',
  templateUrl: './ecom-event-details.component.html',
  styleUrls: [
    './ecom-event-details.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class EcomEventDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly EVENT_NAME_MAX_LENGTH = EventNameSchema.maxLength ?? 50;
  readonly eventTypeOptions = EventTypeOptions;
  readonly IndividualOrGroup = IndividualOrGroup;
  readonly OnlineOrInStore = OnlineOrInStore;

  private readonly environment = inject(EVENT_MANAGER_ENVIRONMENT);

  event = toSignal(this.eventService.selectedEvent$);
  loading = computed(() => this.event() === undefined);

  /** used to prevent the eventName from auto saving
   * when the ConfirmSaveEcomEventDetailsModalComponent opens,
   * which happens if you click Prev and there are unsaved changes on eventName.
   * This is only changed (set to true) in the ecomEventDetailsDeactivateGuard */
  preventSave = false;


  form = new FormGroup<EcomEventDetailsFormControls>(
    {
      eventName: new FormControl('', { nonNullable: true, updateOn: 'blur' }),
      eventType: new FormControl('', { nonNullable: true }),
      individualOrGroup: new FormControl('', { nonNullable: true }),
      onlineOrInStore: new FormControl('', { nonNullable: true }),
      eventDate: new FormControl<number>(0, { nonNullable: true }),
    },
    {
      validators: [
        dateIsTooSoonValidator,
        ZodFormUtilities.zodFormGroupValidator(EcomEventDetailsFormSchema),
      ],
    }
  );

  statusChanges = toSignal(this.form.statusChanges);

  formDisabled = computed(() => {
    this.statusChanges();
    return this.form.disabled;
  });

  /** the date form is not affected by form.disable(), so need to manually disable it through its input */
  dateDisabled = computed(() => {
    return this.formDisabled() ? { disabled: true, emitEvent: false } : false;
  });

  readonly minDateOnline = computed(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + + getOnlineLeadTimeDays());
    return date;
  });
  readonly minDateInStore = computed(() => {
    const date = new Date()
    date.setHours(0, 0, 0, 0);
    return date;
  });
  readonly maxDate = getMaxDateForEvent();
  readonly onlineLeadTimeDays = getOnlineLeadTimeDays();
  subscription = new Subscription();
  permissionsForm!: FormGroup<PermissionsForm>;
  coOwners = computed(() => this.event()?.admins.filter((admin) => !admin.isOrganizer) ?? []);
  eventHasCoOwner = computed(() => this.coOwners().length > 0);
  organizer = computed(() => {
    const event = this.event();
    return event ? getOrganizerFromEvent(event) : undefined;
  });
  existingMemberSelectOpen = signal(false);
  selectedMemberIdx = signal<number | undefined>(undefined);
  addNewAdminOpen = signal(false);
  nonAdminMembers = computed(() => {
    console.log("Computing nonAdminMembers");
    const event = this.event();
    if (event === undefined) {
      return [];
    }
    return event.members.filter((member) => !isMemberAdminForEvent(member, event));
  });
  phone = '';
  hasPaidMembers = computed(() => this.event()?.members.some((member) => member.memberProgress?.paid));
  isEventSharedWithRetailer = computed(() => isEventSharedWithRetailer(this.event()));
  hasCoOwnerCapabilities = computed(() => {
    const event = this.event();
    if (event === undefined) {
      return false;
    }
    const eventDate = convertUnixTimestampToDate(event.eventDate);
    // Date with 20 Days prior to event date
    eventDate.setDate(eventDate.getDate() - getCoOwnerLeadByDays());
    if (new Date() < eventDate) {
      return true;
    }
    return false;
  });
  displayAddCurrentMembers = computed(() => {
    return (this.coOwners().length < 1 || this.sendingInvite()) &&
      (this.nonAdminMembers().length > 0 ||
        (this.nonAdminMembers().length > 0 && this.addNewAdminOpen()))
  });
  isOnlineDisabled = computed(() => {
    return (
      this.form.controls.eventDate.value <
      convertDateToUnixTimestamp(this.minDateOnline().toDateString())
    );
  });

  addNewAdminState = computed(() => {
    return !this.hasCoOwnerCapabilities() ? 'disabled'
      : this.addNewAdminOpen() ? 'selected'
        : '';
  });

  addCurrentMemberState = computed(() => {
    return !this.hasCoOwnerCapabilities() ? 'disabled'
      : (this.existingMemberSelectOpen() || this.sendingInvite()) ? 'selected'
        : '';
  });

  enableCoOwnerSection = computed(() => (this.event()?.members.length ?? 0) > 0);


  requiredWarn = signal(false);
  isSaving = signal(false);
  saved = signal(false);
  saveError = signal(false);
  sendingInvite = signal(false);
  errorSending = signal(false);
  readonly eventCreationEnabled = signal(this.environment.dealerPortal
    ? false
    : this.environment.enableEventCreation
  ).asReadonly();

  constructor(
    private eventService: EventService,
    private eventManagerService: EventManagerService,
    private communicationQueueService: EcomCommunicationQueueService,
    private route: ActivatedRoute,
    private scrollService: ScrollService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    /// Reset the form when the event changes
    effect(() => {
      const event = this.event();
      console.log('event changed', event);
      if (event !== undefined) {
        this.initializeDetailsForm(event);
      }
    })

    effect(() => {
      if (this.isEventSharedWithRetailer() || this.hasPaidMembers()) {
        this.form.disable({ emitEvent: false });
      }
    });

    effect(() => {

    })
  }

  getFirstSchemaFieldError = ZodFormUtilities.getFirstSchemaFieldErrorMessage;

  ngOnInit(): void {

    this.subscription.add(
      this.route.queryParams.subscribe((queryParam) => {
        if (
          queryParam.previousRoute === 'track-progress' ||
          queryParam.previousRoute === 'order-confirmation'
        ) {
          this.scrollService.scrollToAnchor(
            'event-permissions-container',
            [0, 100]
          );
        }
      })
    );

  }

  ngAfterViewInit(): void {
    // this is in afterViewInit to avoid triggering the form valueChanges during initialization
    this.subscription.add(
      this.form.valueChanges
        .pipe(
          debounceTime(500),
          tap((value) => {
            console.log('***valueChange', value);
            const { eventDate, ...formWithoutEventDate } = value;
            this.updateEvent(formWithoutEventDate); // eventDate saves are triggered by onDateBlur, but validation changes happen immediately
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /** Trigger an update of eventDate when date form blurs.
   * This is to prevent the eventDate from saving on every keystroke.
   */
  onDateBlur() {
    this.updateEvent({ eventDate: this.form.controls.eventDate.value });
  }


  setDate(eventDate: number | undefined) {
    console.log('setDate', eventDate);
    if (eventDate !== undefined) {
      this.form.controls.eventDate.setValue(eventDate, { emitEvent: false });
      console.log('eventDate validity: ', this.form.controls.eventDate.valid);
      this.form.controls.eventDate.updateValueAndValidity();
      console.log('form validity: ', this.form.valid);
      // this.form.updateValueAndValidity();
      console.log('eventDate: ', this.form.controls.eventDate.value);
    }
  }

  setPhone(phone: string | undefined) {
    if (phone !== undefined) {
      this.phone = phone;
    }
  }

  initializeDetailsForm(event: Event | undefined): void {
    console.log('initializeDetailsForm');
    if (event === undefined) {
      console.error('Event is undefined');
      return;
    }
    const individualOrGroup = getIndividualView(event) || getIndividualInStoreView(event) ? this.IndividualOrGroup.individual : this.IndividualOrGroup.group;
    const onlineOrInStore = isEventInStore(event) ? this.OnlineOrInStore.inStore : this.OnlineOrInStore.online;
    const { eventName, eventDate, eventType } = event;
    console.log('eventType: ', event.eventType);

    this.form.patchValue(
      {
        eventName,
        eventType,
        eventDate,
        individualOrGroup: individualOrGroup,
        onlineOrInStore: onlineOrInStore,
      },
      { onlySelf: false, emitEvent: false }
    );

    this.form.controls.eventType.disable({ emitEvent: false });
    // if (this.hasPaidMembers() ? this.shared) {
    //   this.form.disable({ emitEvent: false });
    // }
  }

  // hasCoOwnerCapabilities(event: Event | undefined): boolean {
  //   if (event === undefined) {
  //     return false;
  //   }
  //   const eventDate = convertUnixTimestampToDate(event.eventDate);
  //   // Date with 20 Days prior to event date
  //   eventDate.setDate(eventDate.getDate() - getCoOwnerLeadByDays());
  //   if (new Date() < eventDate) {
  //     return true;
  //   }
  //   return false;
  // }

  /** If event is online, checks if the event date is after the min date. If not, sets dateWarning to true. */
  // dateChange(): void {
  //   if (this.form.controls.onlineOrInStore.value === 'online') {
  //     const minUnixTimestamp = convertDateToUnixTimestamp(
  //       this.minDate.toDateString()
  //     );
  //     if (this.eventDate < minUnixTimestamp && !this.form.disabled) {
  //       this.dateWarning = true;
  //       this.saved.set(false);
  //     } else {
  //       this.dateWarning = false;
  //     }
  //   } else {
  //     this.dateWarning = false;
  //   }
  // }

  /**
   * If the event is online, sets this.minDate to the current date + onlineLeadTimeDays.
   * Then, calls dateChange() to check if the event date is after the min date and sets dateWarning accordingly.
   */
  // onlineOrInStoreChange(): void {
  //   const newDate: Date = new Date();
  //   newDate.setHours(0, 0, 0, 0);
  //   if (this.form.controls.onlineOrInStore.value === 'online') {
  //     newDate.setDate(newDate.getDate() + this.onlineLeadTimeDays);
  //   }
  //   this.minDate = newDate;
  //   this.dateChange();
  // }

  async updateEvent(
    valueChanges: ObservedValueOf<typeof this.form.valueChanges>
  ): Promise<void> {
    console.log('updateEvent called');
    const currentEvent = this.event();
    if (currentEvent === undefined) {
      console.error('Event is undefined');
      return;
    }

    if (this.preventSave) {
      console.log('PreventSave is true.  Not saving.');
      return;
    }

    if (this.hasPaidMembers() || this.isEventSharedWithRetailer()) {
      return;
    }

    if (this.form.valid) {
      const {
        eventDate,
        eventName,
        eventType,
        individualOrGroup,
        onlineOrInStore,
      } = valueChanges;
      const changedIndividualToGroup =
        currentEvent.isSingleUser &&
        individualOrGroup === this.IndividualOrGroup.group;
      const changedGroupToIndividual =
        !currentEvent?.isSingleUser &&
        individualOrGroup === this.IndividualOrGroup.individual;

      const isSingleUser =
        individualOrGroup === this.IndividualOrGroup.individual;

      const isOnline = onlineOrInStore === this.OnlineOrInStore.online;
      const existingEventIsInStore = currentEvent.inStoreInfo !== undefined;
      const changedInStoreToOnline = existingEventIsInStore && isOnline;

      const isInStore = onlineOrInStore === this.OnlineOrInStore.inStore;
      const existingEventIsOnline = currentEvent.inStoreInfo === undefined;
      const changedOnlineToInStore = existingEventIsOnline && isInStore;

      console.log({
        changedIndividualToGroup,
        changedGroupToIndividual,
        changedInStoreToOnline,
        changedOnlineToInStore,
      })
      // build the update object
      const updateEventDetails = this.eventManagerService.updateEventDetails;
      const eventDetailsUpdates: Parameters<typeof updateEventDetails>[1] = {};
      // console.log("valueChanges.eventName", valueChanges.eventName);
      // console.log("event.eventName", event.eventName);
      if (
        eventName !== undefined &&
        eventName.trim() !== currentEvent.eventName
      ) {
        console.log('Event name changed');
        eventDetailsUpdates.eventName = eventName.trim();
      }
      if (
        eventType !== undefined &&
        eventType !== currentEvent.eventType &&
        eventType !== ''
      ) {
        // console.log("valueChanges.eventType", valueChanges.eventType);
        // console.log("event.eventType", event.eventType);
        console.log('Event type changed');
        eventDetailsUpdates.eventType = eventType;
      }
      if (eventDate !== undefined && eventDate !== currentEvent.eventDate) {
        // only want to trigger the save on dateBlur, not on every value change (like when typing), so eventDate comes from onDateBlur instead of valueChanges
        console.log('Event date changed');
        eventDetailsUpdates.eventDate = eventDate;
      }

      /// The following checks have not been fully fleshed out or tested in any way.  These are just rough placeholders.
      /// There is other scenarios to consider, like changing both individualOrGroup and onlineOrInStore at the same time.
      if (changedIndividualToGroup) {
        console.log(
          'Changed Individual to Group. Calling switchToGroup().'
        );
        return this.switchToGroup();
        // eventDetailsUpdates.inStoreInfo.shareEventDetails.eventSize = undefined;
      }
      if (changedGroupToIndividual) {
        console.log(
          'Changed Group to Individual. Calling switchToIndividual()'
        );
        return this.switchToIndividual();
        // eventDetailsUpdates.inStoreInfo.shareEventDetails.eventSize = 1;
      }
      if (changedInStoreToOnline) {
        // if the event was previously in store and is now online
        console.log(
          'Changed from InStore to Online.  Calling switchToOrderOnline().'
        );
        return this.switchToOrderOnline();
        // eventDetailsUpdates.inStoreInfo = undefined;
      }
      if (changedOnlineToInStore) {
        // if the event was previously online and is now in store
        console.log(
          'Changed from Online to InStore. Calling switchToOrderInStore().'
        );
        return this.switchToOrderInStore();
        // eventDetailsUpdates.inStoreInfo = { isCreatedByDealer: false };
      }

      if (Object.keys(eventDetailsUpdates).length === 0) {
        console.log('No changes to save');
        if (this.saveError() === true) {
          // only remove "not saved" message if it was previously shown, otherwise leave "saved" message
          this.saved.set(false);
          this.saveError.set(false);
        }
        return;
      }

      this.isSaving.set(true);
      this.saved.set(false);
      this.saveError.set(false);
      this.form.disable({ emitEvent: false });

      console.log('Saving with updateEventDetails');
      try {
        // const updatedEvent = await firstValueFrom(
        //   of(currentEvent).pipe(
        //     delay(3000),
        //   )
        // )

        const { updatedEvent } =
          await this.eventManagerService.updateEventDetails(
            currentEvent.id,
            eventDetailsUpdates
          );
        console.log('details successfully updated');
        this.saved.set(true)
        this.saveError.set(false);
        console.log('Setting selected event with save result');
        this.eventService.setSelectedEventWithEvent(updatedEvent, 'EcomEventDetailsComponent -- updateEvent()');
        console.log('After setting selected event.');
        this.isSaving.set(false);
        this.reEnableForm();
      } catch (error) {
        console.error('Error updating event details', error);
        alert(
          'Sorry, there was an error saving your event details.  Please try again or contact Customer Service.'
        );
        this.initializeDetailsForm(currentEvent); // reset the form to the previous values
        try {
          await this.eventService.getSelectedEvent(true, 'EcomEventDetailsComponent -- updateEvent()');
          this.isSaving.set(false);
          this.saved.set(false);
          this.saveError.set(true);
          this.reEnableForm();
          return;
        } catch (error) {
          console.error('Error reloading event', error);
          this.isSaving.set(false);
          this.saved.set(false);
          this.saveError.set(true);
          alert('The page will reload now.');
          window.location.reload();
        }
      }

      // this.numberOfSaves += 1;
    } else {
      console.log('Details Form is not valid.');
      // console.log(this.form);
      this.saveError.set(true);
      this.saved.set(false);
    }
  }

  /** Re-enables the eventName and eventDate, but not eventType (because this needs to stay disabled) */
  reEnableForm() {
    console.log('Re-enabling form.');
    this.form.controls.eventName.enable({ emitEvent: false });
    this.form.controls.eventDate.enable({ emitEvent: false });
    this.form.controls.individualOrGroup.enable({ emitEvent: false });
    this.form.controls.onlineOrInStore.enable({ emitEvent: false });
  }

  toggleAddCurrentMemberCoOwner(): void {
    console.log('Toggling add current member Co-Owner');
    if (!this.hasCoOwnerCapabilities()) {
      return;
    }
    this.addNewAdminOpen.set(false);
    this.existingMemberSelectOpen.set(!this.existingMemberSelectOpen());
  }

  closeExisting(): void {
    this.existingMemberSelectOpen.set(false);
  }

  toggleAddNonMemberCoOwner(): void {
    console.log('Toggling add non-member Co-Owner');
    this.closeExisting();
    if (!this.hasCoOwnerCapabilities()) {
      return;
    }
    if (this.addNewAdminOpen()) {
      this.addNewAdminOpen.set(!this.addNewAdminOpen());
      return;
    }
    this.addNewAdminOpen.set(!this.addNewAdminOpen());
    // const newAdmin: EventAdmin = {
    //   // id: (Math.floor(Math.random() * 999998) + 1).toString(),
    //   id: generateUuid(),
    //   name: '',
    //   role: '',
    //   email: '',
    //   phone: '',
    //   isOrganizer: false,
    // };
    this.permissionsForm = new FormGroup({
      id: new FormControl('', { nonNullable: true }),
      userId: new FormControl('', { nonNullable: true }),
      sendEmailNotification: new FormControl(false, { nonNullable: true }),
      memberSelected: new FormControl(),
      firstName: new FormControl('', { nonNullable: true }),
      lastName: new FormControl('', { nonNullable: true }),
      role: new FormControl('', { nonNullable: true }),
      email: new FormControl('', {
        validators: JfwEmailValidator.isValid(),
        nonNullable: true,
      }),
    });
    // this.filterNonAdmins(this.event());
  }

  // addChildForm<key extends keyof EcomEventDetailsFormControls>(
  //   name: key,
  //   control: AbstractControl<any, any>
  //   // name: key,
  //   // group: Exclude<EcomEventDetailsFormControls[key], undefined>
  // ) {
  //   this.form.setControl(name, control as any);
  // }

  addChildPermissionsForm<key extends keyof PermissionsForm>(
    name: key,
    group: Exclude<PermissionsForm[key], undefined>
  ) {
    this.permissionsForm.setControl(name, group);
  }

  async inviteCoOwner(): Promise<void> {
    const event = this.event();
    if (event === undefined) {
      console.error('Event is undefined');
      return;
    }
    if (this.permissionsForm.valid) {
      this.errorSending.set(false);
      this.sendingInvite.set(true);
      const admin: EventAdmin = {
        // id: (Math.floor(Math.random() * 999998) + 1).toString(),
        id: generateUuid(),
        name: '',
        lastName: '',
        role: '',
        email: '',
        phone: '',
        isOrganizer: false,
      };

      admin.id =
        this.permissionsForm.controls.id.value == ''
          ? admin.id
          : this.permissionsForm.controls.id.value;
      admin.userId = this.permissionsForm.controls.userId.value;
      admin.firstName = this.permissionsForm.controls.firstName.value;
      admin.lastName = this.permissionsForm.controls.lastName.value;
      admin.email = this.permissionsForm.controls.email.value;
      admin.phone = this.phone;
      if (admin.inviteSent === false) {
        admin.inviteSent =
          this.permissionsForm.controls.sendEmailNotification.value === true;
      }
      try {
        const { updatedEvent } = await this.eventManagerService.addAdminToEvent(
          event.id,
          admin
        );

        this.eventService.setSelectedEventWithEvent(updatedEvent,
          'EcomEventDetailsComponent -- inviteCoOwner()');

        // await this.eventService.updateEvent(this.event);
        if (admin.email !== undefined) {
          let response =
            await this.communicationQueueService.queueEmailCoOwnerInvite(
              event,
              admin.email,
              admin.firstName,
              admin.lastName,
              admin.phone
            );
          if (response === undefined) {
            this.errorSending.set(true);
          }
        }

        await this.setIsOrganizerOnUserEvent(admin, event.id, true);
        this.closeExisting();
      } catch (error) {
        this.closeExisting();
        this.addNewAdminOpen.set(false);
        this.sendingInvite.set(false);
        alert('Encountered unexpected error.');
      }
      this.closeExisting();
      this.addNewAdminOpen.set(false);
      this.sendingInvite.set(false);
    } else {
      console.log('permissionsForm is NOT valid');
      this.requiredWarn.set(true);
      this.permissionsForm.markAllAsTouched();
    }
  }

  async setIsOrganizerOnUserEvent(
    admin: EventAdmin,
    eventId: string,
    isOrganizer: boolean
  ) {
    if (admin.userId !== undefined && admin.userId !== '') {
      const userEvents =
        await this.eventManagerService.getUserEventsForCurrentUserByEventId(
          eventId
        );
      if (userEvents !== undefined && userEvents.length > 0) {
        const userEvent: UserEvent = userEvents[0];
        const userEventDocId = userEvent.id;
        // userEvent.isOrganizer = isOrganizer;
        // this.eventManagerService.updateUserEvent(userEvent)
        console.log(
          'Setting isOrganizer for UserEvent with id: ',
          userEventDocId,
          ' to: ',
          isOrganizer
        );
        const result = await this.eventManagerService.setIsOrganizer(
          userEventDocId,
          isOrganizer
        );
        console.log('UserEvent update was successful.');
      }
    }
  }

  phoneFormat(phone: string): string {
    let phoneFormatted = phone.split('');
    phoneFormatted.splice(0, 0, '(');
    phoneFormatted.splice(4, 0, ')');
    phoneFormatted.splice(5, 0, ' ');
    phoneFormatted.splice(9, 0, '-');
    return phoneFormatted.join('');
  }


  selectMemberChange(memberId: string, idx: number): void {
    let memberToPopulate: EventMember | undefined;
    this.event()?.members.forEach((member: EventMember) => {
      if (member.id === memberId) {
        memberToPopulate = member;
      }
    });
    if (memberToPopulate) {
      const coOwnerError = this.getCoOwnerError(memberToPopulate);
      if (coOwnerError !== '') {
        console.log('Co-Owner Error: ', coOwnerError, '- Not selecting.');
        return;
      }

      this.permissionsForm = new FormGroup({
        id: new FormControl('', { nonNullable: true }),
        userId: new FormControl('', { nonNullable: true }),
        sendEmailNotification: new FormControl(false, { nonNullable: true }),
        memberSelected: new FormControl(),
        firstName: new FormControl('', { nonNullable: true }),
        lastName: new FormControl('', { nonNullable: true }),
        role: new FormControl('', { nonNullable: true }),
        email: new FormControl('', {
          validators: JfwEmailValidator.isValid(),
          nonNullable: true,
        }),
      });

      // toggle off if already selected
      if (this.selectedMemberIdx() === idx) {
        // console.log("Member already selected. Deselecting.");
        this.selectedMemberIdx.set(undefined);
        return;
      }

      this.selectedMemberIdx.set(idx);
      this.permissionsForm.controls.id.setValue(memberToPopulate.id!);
      this.permissionsForm.controls.userId.setValue(memberToPopulate.userId!);
      this.permissionsForm.controls.firstName.setValue(
        memberToPopulate?.firstName!
      );
      this.permissionsForm.controls.lastName.setValue(
        memberToPopulate?.lastName!
      );
      this.phone = memberToPopulate.phone!;
      this.permissionsForm.controls.email.setValue(memberToPopulate.email!);

      this.permissionsForm.disable();
      this.permissionsForm.controls.memberSelected.enable();
      this.permissionsForm.controls.sendEmailNotification.enable();
    }
  }

  getCoOwnerError(member: EventMember) {
    if (
      !member?.email ||
      !member.phone ||
      !member?.firstName ||
      !member?.lastName
    )
      return '*missing info';
    return '';

    // if (!member?.email) return '*missing email address';
    // if (!member.phone) return '*missing phone number';
    // if (!member?.firstName) return '*missing first name';
    // if (!member?.lastName) return '*missing last name';
    // return '';
  }

  editCoOwner() {
    const event = this.event();
    if (event === undefined) {
      console.error('Event is undefined');
      return;
    }
    this.router.navigate(['event', event.id, 'add-members']);
  }

  private async switchToOrderOnline() {
    const currentEvent = this.event();
    if (currentEvent !== undefined) {
      const inStoreEventChange: InStoreEventConfirmChangeData = {
        action: 'order-online',
        event: currentEvent,
      };

      this.saved.set(false);
      this.saveError.set(false);
      this.form.disable({ emitEvent: false });

      console.log('Opening InStoreConfirmChangeModalComponent');

      const { success } = await firstValueFrom(this.dialog.open(InStoreConfirmChangeModalComponent, {
        width: '300px',
        minWidth: '300px',
        // minHeight: '300px',
        data: inStoreEventChange,
        disableClose: true,
      }).afterClosed());
      console.log('InStoreConfirmChangeModalComponent closed.  Value received: ', success);
      /// if we get here, the modal was closed
      if (success === true) {
        this.saved.set(true)
      } else { // the modal was closed without saving
        this.initializeDetailsForm(currentEvent);
        this.saved.set(false);
        this.reEnableForm();
      }
    }
  }

  private async switchToOrderInStore() {
    const currentEvent = this.event();
    if (currentEvent !== undefined) {
      const inStoreEventChange: InStoreEventConfirmChangeData = {
        action: 'order-inStore',
        event: currentEvent,
      };

      this.saved.set(false);
      this.saveError.set(false);
      this.form.disable({ emitEvent: false });

      console.log('Opening InStoreConfirmChangeModalComponent');

      const { success } = await firstValueFrom(this.dialog.open(InStoreConfirmChangeModalComponent, {
        width: '300px',
        minWidth: '300px',
        // minHeight: '300px',
        data: inStoreEventChange,
        disableClose: true,
      }).afterClosed());
      console.log('InStoreConfirmChangeModalComponent closed.  Value received: ', success);
      /// if we get here, the modal was closed
      if (success === true) {
        this.saved.set(true)
      } else { // the modal was closed without saving
        this.initializeDetailsForm(currentEvent);
        this.saved.set(false);
        this.reEnableForm();
      }
    }


  }

  private async switchToIndividual() {
    const currentEvent = this.event();
    if (currentEvent !== undefined) {
      const switchToIndividualEventChange: GroupIndividualConfirmChangeData = {
        action: 'switch-to-individual',
        event: currentEvent,
      };

      this.saved.set(false);
      this.saveError.set(false);
      this.form.disable({ emitEvent: false });

      console.log('Opening GroupIndividualConfirmChangeModalComponent');

      const { success } = await firstValueFrom(this.dialog.open(GroupIndividualConfirmChangeModalComponent, {
        width: '300px',
        minWidth: '300px',
        // minHeight: '300px',
        data: switchToIndividualEventChange,
        disableClose: true,
      }).afterClosed());
      console.log('GroupIndividualConfirmChangeModalComponent closed.  Value received: ', success);
      /// if we get here, the modal was closed
      if (success === true) {
        this.saved.set(true)
      } else { // the modal was closed without saving
        this.initializeDetailsForm(currentEvent);
        this.saved.set(false);
        this.reEnableForm();
      }
    }
  }

  private async switchToGroup() {
    const currentEvent = this.event();
    if (currentEvent !== undefined) {
      const switchToGroupEventChange: GroupIndividualConfirmChangeData = {
        action: 'switch-to-group',
        event: currentEvent,
      };

      this.saved.set(false);
      this.saveError.set(false);
      this.form.disable({ emitEvent: false });

      console.log('Opening GroupIndividualConfirmChangeModalComponent');

      const { success } = await firstValueFrom(this.dialog.open(GroupIndividualConfirmChangeModalComponent, {
        width: '300px',
        minWidth: '300px',
        // minHeight: '300px',
        data: switchToGroupEventChange,
        disableClose: true,
      }).afterClosed());
      console.log('GroupIndividualConfirmChangeModalComponent closed.  Value received: ', success);
      /// if we get here, the modal was closed
      if (success === true) {
        this.saved.set(true)
      } else { // the modal was closed without saving
        this.initializeDetailsForm(currentEvent);
        this.saved.set(false);
        this.reEnableForm();
      }
    }
  }
}
