import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  inject,
} from '@angular/core';
import { EventService } from '@jfw-library/ecommerce/core';
import { isPaidForMember } from 'business-logic';
import { Event, EventMember, Look } from 'common-types';
import { Subscription } from 'rxjs';
import { EventManagerService } from '../../services/event-manager-service';

type AssignLookUpdate = {
  memberId: string;
  lookId?: string | undefined;
};

@Component({
  selector: 'app-assign-looks',
  templateUrl: './assign-looks.component.html',
  styleUrls: [
    './assign-looks.component.scss',
    '../../event-manager.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignLooksComponent implements OnInit, OnDestroy {
  @ViewChild('skipRef') skipRef!: ElementRef;

  /**
   * The event being managed.  Updated through the subscription to selectedEvent$.
   */
  event!: Event;
  /** This is the local working cache of members array, which does not directly change this.event.members on changes.
   * This is to avoid rebuilding the carousel every time event updates.
   * event.members is updated through the subscription to selectedEvent$.
   * If a saveAssignLookQueueToDb fails, this.members is reset to this.event.members.
   */
  members: EventMember[] = [];
  looks: Look[] = [];
  subscription = new Subscription();
  validatorMsg = '';
  isMobile = false;
  nextClicked = false;
  memberLookIndexes: number[] = [];
  selectTimeout: NodeJS.Timeout | undefined;
  assignLooksQueue: AssignLookUpdate[] = [];
  assignLooksQueueIsEmpty = true;
  saving = false;
  showWarning$ = this.eventManagerService.showWarning$;
  // private lookService = inject(LookService);
  // assignLooksQueue: Parameters<typeof this.lookService.assignLooks>[1] = [];
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  private document: Document = inject(DOCUMENT);

  constructor(
    private eventService: EventService,
    private eventManagerService: EventManagerService,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {}

  // TO-DO
  // Need to reevaluate updateEvent()/selectedEvent$ use in event manager
  // Add/Select Look is going to have a similar issue

  // members and looks are being stored in this component to avoid
  // rebuilding the carousel every time event updates

  // members/looks only updated if the count is somehow different
  // (ex. member is deleted, and then user advances to assign looks
  // before the event update is finished processing)

  // this.event.members is being replaced with this.members before calling updateEvent()

  ngOnInit(): void {
    this.subscription.add(
      this.eventService.selectedEvent$.subscribe({
        next: (event) => {
          console.log('Event received in AssignLooksComponent');
          const currentEventUpdateTime = this.event?.update_time;
          const incomingEventUpdateTime = event?.update_time;
          // need to look at both seconds and _seconds because event might have been serialized with JSON.stringify
          const currentEventUpdateTimeSeconds = currentEventUpdateTime ? currentEventUpdateTime?.seconds ?? currentEventUpdateTime?._seconds ?? 0 : 0;
          const incomingEventUpdateTimeSeconds = incomingEventUpdateTime ? incomingEventUpdateTime.seconds ?? incomingEventUpdateTime._seconds ?? 0 : 0;
          const dif = incomingEventUpdateTimeSeconds - currentEventUpdateTimeSeconds;
          console.log({ currentEventUpdateTime, incomingEventUpdateTime, currentEventUpdateTimeSeconds, incomingEventUpdateTimeSeconds, dif });
          const isNewEvent = !this.event ? true : dif !== 0;
          console.log("isNewEvent in AssignLooksComponent: ", isNewEvent);

          // make a deep copy of the event so that changes to this.members and this.looks do not affect the this.event
          this.event = JSON.parse(JSON.stringify(event));

          if (
            this.members.length !== event.members.length ||
            this.looks.length !== event.looks.length ||
            isNewEvent
          ) {
            this.members = JSON.parse(JSON.stringify(event.members));
            this.looks = JSON.parse(JSON.stringify(event.looks));
            this.setMemberLookIndexes();
            this.cdr.detectChanges();
          }
        },
      })
    );

    // this.subscription.add(
    //   this.eventService.validateStep$.subscribe({
    //     next: () => this.validate(),
    //   })
    // );

    this.subscription.add(
      this.breakpointObserver.observe(Breakpoints.XSmall).subscribe({
        next: (result) => (this.isMobile = result.matches),
      })
    );

    this.subscription.add(
      this.eventManagerService.results$.subscribe(async (validator) => {
        if (validator !== undefined) {
          this.validatorMsg = validator.msg;
          this.eventService.canProceed$.next(validator.valid);
          if (validator?.isError) {
            this.members = JSON.parse(JSON.stringify(this.event.members));
            this.looks = JSON.parse(JSON.stringify(this.event.looks));
            this.setMemberLookIndexes();
          }
          this.cdr.detectChanges();
        }
      })
    );

    this.subscription.add(
      this.eventManagerService.isSaving$.subscribe((saving) => {
        this.saving = saving;
        this.cdr.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Sets the memberLookIndexes array to the index of the look assigned to each member.  Only used by carousel to make sure selected look is displayed.
   */
  private setMemberLookIndexes(): void {
    console.log('Setting memberLookIndexes');
    this.members.forEach((member, idx) => {
      const memberLookId = member.memberLook?.id;
      let lookIndex = this.looks.findIndex((look) => look.id === memberLookId);
      if (lookIndex === -1) {
        lookIndex = 0;
      }
      this.memberLookIndexes[idx] = lookIndex;
    });
  }

  public tabTopPage(event: any): void {
    event.preventDefault();
    let prev = this.document.getElementById('previous-button');
    let next = this.document.getElementById('next-button');
    if (prev !== null) {
      prev?.focus();
    } else {
      next?.focus();
    }
  }

  public skipClose(): void {
    this.skipRef.nativeElement.blur();
  }

  public useCarousel(): boolean {
    return this.looks.length > 3 || this.isMobile;
  }

  private queueAssignLook(memberId: string, lookId: string | undefined): void {
    const updateIndex = this.assignLooksQueue.findIndex(
      (update) => update.memberId === memberId
    );
    if (updateIndex !== -1) {
      // if member already has an update queued, then update the lookId
      this.assignLooksQueue[updateIndex].lookId = lookId;
    } else {
      this.assignLooksQueue.push({ memberId, lookId });
    }

    console.log(
      'number of look assignments in queue: ',
      this.assignLooksQueue.length
    );
    this.eventManagerService.setAssignLooksToUpdate(this.assignLooksQueue);
  }

  public onSelect(memberToUpdate: EventMember, look: Look): void {
    this.nextClicked = false;
    if (!memberToUpdate?.id || !look?.id) {
      return;
    }

    // update the look for the member in the local members array
    const memberIndex = this.members.findIndex(
      (existingMember: EventMember) => {
        return existingMember.id === memberToUpdate.id;
      }
    );

    if (memberIndex !== -1) {
      if (this.members[memberIndex].memberLook?.id === look.id) {
        this.members[memberIndex].memberLook = undefined; // remove the look assignment
        this.queueAssignLook(memberToUpdate.id, undefined); // queue the look assignment to be saved to the db
      } else {
        this.members[memberIndex].memberLook = look; // assign the look
        this.queueAssignLook(memberToUpdate.id, look.id); // queue the look assignment to be saved to the db
      }
      this.setMemberLookIndexes();
      // Used to clear call stack for selecting looks really fast.
    }
    return;
  }

  public isPaidForMember(member: EventMember): boolean {
    return isPaidForMember(member);
  }
}
