import { Location } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '@jfw-library/ecommerce/core';
import { ValidationService } from '@jfw-library/ecommerce/shared';
import { getEventSteps } from 'business-logic';
import { Event, EventStep } from 'common-types';
import { Subscription } from 'rxjs';
import { EventStepperComponent } from './components/event-stepper/event-stepper.component';
import { EventManagerService } from './services/event-manager-service';

@Component({
  selector: 'app-event-manager',
  templateUrl: './event-manager.component.html',
  styleUrls: ['./event-manager.component.scss'],
})
export class EventManagerComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @ViewChild('stepper') stepper!: EventStepperComponent;

  event!: Event;
  hideStepper = false;
  eventSteps: EventStep[] = [];
  eventStep: EventStep | undefined;
  subscription = new Subscription();
  canProceed = true;
  showWarning = false;
  nextClicked = false;
  nextDisabled = false;
  previousDisabled = false;
  nextDisabledMessage = '';
  childRoute = '';
  clickDirection: 'next' | 'prev' | undefined = 'prev';
  hasInvalidStep = false;
  assignLooksQueueIsEmpty = true;
  showWarning$ = this.eventManagerService.showWarning$;

  constructor(
    @Inject('environment') private environment: any,
    private eventService: EventService,
    private eventManagerService: EventManagerService,
    private validationService: ValidationService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.nextDisabled = false;
    this.previousDisabled = false;

    // Set selected event from url param
    // this.subscription.add(
    //   this.activatedRoute.paramMap.subscribe((params) => {
    //     const eventId = params.get('eventId');
    //     if (eventId) {
    //       console.log("Setting selected event from eventId url param: ", eventId);
    //       this.eventService.setSelectedEvent(eventId);
    //     }
    //   })
    // );

    this.subscription.add(
      this.eventService.selectedEvent$.subscribe({
        next: (event) => {
          this.event = event;
          this.eventSteps = getEventSteps(event, this.environment.dealerPortal);
        },
      })
    );

    this.subscription.add(
      this.eventService.canProceed$.subscribe({
        next: (canProceed) => this.onCanProceed(canProceed),
      })
    );

    this.subscription.add(
      this.eventManagerService.isSaving$.subscribe((saving) => {
        this.nextDisabled = saving;
        this.previousDisabled = saving;
        this.cdr.detectChanges();
      })
    );

    this.subscription.add(
      this.eventManagerService.clickDirection$.subscribe((direction) => {
        this.clickDirection = direction;
      })
    );

    this.subscription.add(
      this.eventManagerService.results$.subscribe((validator) => {
        this.hasInvalidStep = validator?.valid === false;
      })
    );

    this.subscription.add(
      this.eventManagerService.assignLooksToUpdate$.subscribe((val) => {
        this.assignLooksQueueIsEmpty = val.length === 0;
      })
    );

    this.subscription.add(
      this.eventManagerService.showWarning$.subscribe((val) => {
        this.showWarning = val;
      })
    );
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showEventLockMsg(): boolean {
    if (
      this.nextDisabledMessage &&
      this.eventStep?.label &&
      this.eventStep?.label !== 'Track Progress' &&
      (this.eventStep?.label === 'Review Event' ||
        this.eventStep?.label === 'Add/Select Look' ||
        this.eventStep.label === 'Event Details')
    ) {
      return true;
    } else {
      return false;
    }
  }

  setHideStepper($event: boolean): void {
    this.hideStepper = $event;
  }

  setChildRoute() {
    const segments = this.router.url.split('/');
    this.childRoute = segments[segments.length - 1].toLowerCase();
  }

  setEventStep($event: EventStep): void {
    this.eventStep = $event;
    const dateLockValidator = this.validationService.isEventDateLocked(
      this.event,
      this.eventStep.route
    );
    if (
      !dateLockValidator.valid &&
      this.eventStep?.label !== 'Track Progress'
    ) {
      this.nextDisabledMessage = dateLockValidator.msg;
    } else {
    }
    this.setChildRoute();

    // Manually setting label for specific use-cases (if applicable)
    if (this.eventStep.label === undefined || this.eventStep.label === '') {
      switch (this.childRoute) {
        case 'track-progress':
          this.eventStep.label = 'Track Progress';
          break;
        default:
          break;
      }
    }
  }

  onPrev(): void {
    // this.showWarning = false;
    this.eventManagerService.previousClicked();
  }

  onNext(): void {
    // this.showWarning = false;
    this.eventManagerService.nextClicked();
  }

  onCanProceed(canProceed: boolean): void {
    this.canProceed = canProceed;
    if (this.nextClicked) {
      if (this.canProceed) {
        this.stepper.onNext();
        this.showWarning = false;
      } else {
        this.showWarning = true;
      }
      this.nextClicked = false;
    } else {
      if (this.canProceed) {
        this.showWarning = false;
      }
    }
  }
}
